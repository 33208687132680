import { EMPLOYEES_URL } from '@/modules/employee/employee.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: EMPLOYEES_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...EMPLOYEES_URL.view,
        component: () => import('@/modules/employee/views/EmployeeViewPage.vue'),
      },
      {
        ...EMPLOYEES_URL.edit,
        component: () => import('@/modules/employee/views/EmployeeEditPage.vue'),
      },
      {
        ...EMPLOYEES_URL.create,
        component: () => import('@/modules/employee/views/EmployeeEditPage.vue'),
      },
      {
        ...EMPLOYEES_URL.forbidden,
        component: () => import('@/modules/employee/views/EmployeeForbiddenPage.vue'),
      },
    ],
  },
];

export function goToEmployeeForbiddenPage($router, creating) {
  $router.push({
    name: EMPLOYEES_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenEmployee($router, employee) {
  $router.push({
    name: EMPLOYEES_URL.view.name,
    params: { id: employee.id },
  });
}

export function goToCreateEmployee($router) {
  $router.push({
    name: EMPLOYEES_URL.create.name,
  });
}
