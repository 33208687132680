<template>
  <getecma-invisible>
    <getecma-table-label
      :label="label"
      :show-range-date="showRangeDate"
      :type="type"
      @fetch="fetch({ start: $event[0], end: $event[1] })"
      @download="downloadCSV" />

    <getecma-row class="d-flex justify-content-center justify-content--space-between">
      <getecma-col class="bg-color--white-600 rounded pt--xl pb--sm px--xxl">
        <getecma-table-header v-if="data.length > 0" :columns="columns" @orderBy="fetch($event)" />
        <div v-if="data.length > 0">
          <getecma-invisible v-for="item in data.slice(0, pagination.limit)" :key="item.id">
            <hr class="line">
            <getecma-table-item v-if="item" ref="index" class="my--md" :data="item" :type="type" />
          </getecma-invisible>
          <hr v-if="pagination.total > pagination.limit" class="line">
          <getecma-pagination
            class="mt--xl"
            :small="true"
            :page.sync="pagination.page"
            :total="pagination.total"
            :page-size="pagination.limit"
            @current-change="fetch()" />
        </div>
        <div v-else>
          <getecma-item-table-layout>
            <template #empty>
              <getecma-header size="sm" class="mt--lg color--info">
              </getecma-header>
            </template>
          </getecma-item-table-layout>
        </div>
      </getecma-col>
    </getecma-row>
  </getecma-invisible>
</template>

<script>
import GetecmaTableLabel from '@/components/table/TableLabel.vue';
import GetecmaTableHeader from '@/components/table/TableHeader.vue';
import GetecmaTableItem from '@/components/table/TableItem.vue';
import GetecmaItemTableLayout from '@/layouts/ItemTableLayout.vue';

export default {
  name: 'GetecmaTable',
  components: {
    GetecmaTableLabel,
    GetecmaTableHeader,
    GetecmaTableItem,
    GetecmaItemTableLayout,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    pagination: {
      type: Object,
    },
    showRangeDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      by: null,
      order: null,
      start: null,
      end: null,
      tablePagination: this.pagination,
    };
  },
  methods: {
    fetch(params = { by: null, order: null, start: null, end: null }) {
      this.by = params.by == null ? this.by : params.by;
      this.order = params.order == null ? this.order : params.order;
      this.start = params.start == null ? this.start : params.start;
      this.end = params.end == null ? this.end : params.end;
      const payload = {
        startDate: this.start,
        endDate: this.end,
        ...this.pagination,
      };
      // THIS NEEDS TO BE OUTSIDE THE PAYLOAD {...} (IDK WHY)
      payload.by = this.by;
      payload.order = this.order;
      this.$emit('fetch', payload);
    },
    downloadCSV() {
      const payload = {
        by: this.by,
        order: this.order,
        startDate: this.start,
        endDate: this.end,
        page: 1,
        limit: 9999999999,
      };
      this.$emit('download', payload);
    },
  },
};
</script>

<style scoped lang="scss">
@import './Table.scss';
</style>
