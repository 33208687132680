<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.63478 20.7743V17.7166C9.63478 16.936 10.2722 16.3033 11.0584 16.3033H13.9326C14.3102 16.3033 14.6723 16.4522
      14.9393 16.7172C15.2063 16.9823 15.3563 17.3418 15.3563 17.7166V20.7743C15.3539 21.0988 15.4821 21.4109 15.7124 21.6412C15.9427
      21.8715 16.2561 22.001 16.5829 22.001H18.5438C19.4596 22.0033 20.3388 21.6438 20.9872 21.0018C21.6356 20.3597 22 19.488 22
      18.5788V9.86784C22 9.13344 21.6721 8.43682 21.1046 7.96565L14.434 2.67685C13.2737 1.74954 11.6111 1.77948 10.4854 2.74796L3.96701
      7.96565C3.37274 8.42293 3.01755 9.12161 3 9.86784V18.5699C3 20.4648 4.54738 22.001 6.45617 22.001H8.37229C9.05123 22.001 9.603 21.4572
      9.60792 20.7832L9.63478 20.7743Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'HomeIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
