export const EMPLOYEES_URL = Object.freeze({
  path: '/employees',
  view: {
    name: 'employee.view',
    path: '/employees/view/:id',
  },
  edit: {
    name: 'employee.edit',
    path: '/employees/edit/:id',
  },
  create: {
    name: 'employee.create',
    path: '/employees/create',
  },
  forbidden: {
    name: 'employee.forbidden',
    path: '/employees/forbidden',
  },
});

export const EMPLOYEES_ROLES = Object.freeze({
  DRIVER: 0,
  HELPER: 1,
  0: 'Motorista',
  1: 'Ajudante',
});
