<template>
  <div class="d-flex justify-content-center">
    <div :style="styles" :class="classes">
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetecmaDivider',
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: 'theme-400-650',
    },
  },
  computed: {
    styles() {
      return `width: ${this.width}%;
        border-bottom: ${this.height}px solid transparent;`;
    },
    classes() {
      return [
        `border-bottom--${this.color}`,
      ];
    },
  },
};
</script>
