<template>
  <el-dropdown trigger="click" class="d-flex">
    <slot name="content" />
    <template #dropdown>
      <el-dropdown-menu slot="dropdown" :style="getStyles">
        <slot name="items" />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
export default {
  name: 'GetecmaDropdown',
  props: {
    minWidth: {
      type: Number,
      default: 170,
    },
  },
  computed: {
    getStyles() {
      return [{
        'min-width': `${this.minWidth}px`,
      }];
    },
  },
};
</script>
<style scoped lang="scss">
@import './Dropdown.scss';
</style>
