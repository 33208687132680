<template>
  <el-tag
    :type="type"
    :size="sizes"
    :effect="effect"
    :closable="closable"
    :class="classes"
    @click="$emit('click')"
    @close="$emit('close')">
    <!-- eslint-disable vue/no-v-html -->
    <router-link v-if="to" :to="to" v-html="label" />
    <!-- eslint-disable vue/no-v-html -->
    <getecma-invisible v-else v-html="label" />
  </el-tag>
</template>

<script>

const TAGS_SIZE = {
  sm: 'small',
  md: 'medium',
  lg: '',
};

export default {
  name: 'GetecmaTag',
  props: {
    to: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      validator(theme) {
        return theme && ['primary', 'success', 'warning', 'danger', 'info'].includes(theme);
      },
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return size && ['sm', 'md', 'lg'].includes(size);
      },
    },
    effect: String,
    closable: Boolean,
  },
  computed: {
    classes() {
      return [{
        'fw--strong': this.closable,
        'fw--regular': !this.closable,
        'border-radius--round': this.rounded,
      }];
    },
    sizes() {
      return TAGS_SIZE[this.size];
    },
  },
};
</script>
<style scoped lang="scss">
@import './Tag.scss';
</style>
