import Vue from 'vue';

const state = Vue.observable({
  user: null,
});

export const mutations = {
  setUser(user) {
    state.user = user;
  },
};

export const getters = {
  getUser() {
    return state.user;
  },
};
