<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
import { SIZES, lg } from '@/components/components.constants';
import { validator } from '@/components/components.validators';
import { mixinColors } from '@/mixins/colors/mixinColors';
import { mixinClasses } from '@/mixins/classes/mixinClasses';

export default {
  name: 'GetecmaHeader',
  mixins: [mixinColors, mixinClasses],
  props: {
    size: {
      type: String,
      default: lg,
      validator: size => validator(size, ...SIZES),
    },
    strong: {
      type: String,
      default: 'strong',
    },
    theme: {
      type: String,
      default: 'header',
    },
    color: {
      type: String,
      default: 'theme-900-100',
    },
  },
  data() {
    return {
      mixins: {
        size: 'getecma-header-',
        strong: 'fw-',
        theme: 'getecma',
        color: 'color-',
      },
    };
  },
};
</script>
