import { get, put, post, remove } from '@/helpers/http';

export function getGroupById(id) {
  return get(`/groups/${id}`);
}

export function updateGroupInformation(group) {
  return put(`/groups/${group.id}`, group);
}

export function createNewGroup(group) {
  return post('/groups/', group);
}

export function removeGroup(group) {
  return remove(`/groups/${group.id}`);
}

export function fetchGroups(pagination = {}) {
  return get('/groups', { ...pagination });
}

export function fetchGroupsByCapacity(idCapacity) {
  return post('/groups-capacity', { capacity_id: idCapacity });
}

export function fetchCustomersByGroup(idGroup) {
  return post('/customers-group', { group_id: idGroup });
}
