import * as customerResource from '@/modules/customer/customer.resource';

export function fetchCustomers(pagination = {}) {
  return new Promise((resolve, reject) =>
    customerResource
      .fetchCustomers(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchCustomersCollects(pagination = {}) {
  return new Promise((resolve, reject) =>
    customerResource
      .fetchCustomersCollects(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getCustomerById(id) {
  return new Promise((resolve, reject) =>
    customerResource
      .getCustomersById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewCustomer(customer) {
  return new Promise((resolve, reject) =>
    customerResource
      .createNewCustomer(customer)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateCustomerInformation(customer) {
  return new Promise((resolve, reject) =>
    customerResource
      .updateCustomerInformation(customer)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeCustomer(customer) {
  return new Promise((resolve, reject) =>
    customerResource
      .removeCustomer(customer)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchAllCustomersPerActivation() {
  return new Promise((resolve, reject) =>
    customerResource
      .fetchAllCustomersPerActivation()
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
