<template>
  <getecma-item-table-layout class="fs--sm">
    <template #name>
      <div class="title d-flex align-items-center" @click="goToItem">
        {{ data[keys[1]] }}
      </div>
    </template>
    <template #firstColumn>
      {{ data[keys[2]] }}
    </template>
    <template #secondColumn>
      {{ data[keys[3]] }}
    </template>
    <template #thirdColumn>
      {{ data[keys[4]] }}
    </template>
    <template #fourthColumn>
      {{ data[keys[5]] }}
    </template>
  </getecma-item-table-layout>
</template>

<script>
import GetecmaItemTableLayout from '@/layouts/ItemTableLayout.vue';

export default {
  name: 'GetecmaTableItem',
  components: {
    GetecmaItemTableLayout,
  },
  props: {
    data: Object,
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keys: Object.keys(this.data),
    };
  },
  methods: {
    goToItem() {
      // TODO add links
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/variables/colors';

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .title:hover {
    color: $primary;
  }
</style>
