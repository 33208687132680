import * as homeResource from '@/modules/home/home.resource';

export function fetchCollects(vehicleId) {
  return new Promise((resolve, reject) =>
    homeResource
      .fetchCollects(vehicleId)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getCollectById(id) {
  return new Promise((resolve, reject) =>
    homeResource
      .getCollectById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchEmployeesByCollect(id) {
  return new Promise((resolve, reject) =>
    homeResource
      .fetchEmployeesByCollect(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewCollect(collect) {
  return new Promise((resolve, reject) =>
    homeResource
      .createNewCollect(collect)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateCollectInformation(collect) {
  return new Promise((resolve, reject) =>
    homeResource
      .updateCollectInformation(collect)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeCollect(collectId) {
  return new Promise((resolve, reject) =>
    homeResource
      .removeCollect(collectId)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function generateCollects(month, year) {
  return new Promise((resolve, reject) =>
    homeResource
      .generateCollects(month, year)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchHolidays() {
  return new Promise((resolve, reject) =>
    homeResource
      .fetchHolidays()
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
