<template>
  <validation-observer v-slot="{ handleSubmit, validate, reset }">
    <form
      novalidate
      :class="formClass"
      @submit.prevent="handleSubmit(submitForm)">
      <slot :validate="validate" :reset="reset" />
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: 'GetecmaFormValidate',
  inheritAttrs: false,
  props: {
    submit: {
      type: Function,
      default: () => {},
    },
    formClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    submitForm() {
      if (this.submit) {
        this.submit();
      }
    },
  },
};
</script>
