<template>
  <el-button
    :round="round"
    :type="theme"
    :plain="plain"
    :icon="getIcon"
    :circle="hasIcon"
    :class="getClasses"
    :disabled="disabled"
    :native-type="nativeType"
    v-on="$listeners">
    <slot />
  </el-button>
</template>

<script>
import { SIZES, md, base } from '@/components/components.constants';
import { validator, themeValidator } from '@/components/components.validators';

const NATIVE_TYPES = ['button', 'submit', 'reset'];

export default {
  name: 'GetecmaButton',
  inheritAttrs: false,
  props: {
    plain: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: base,
      validator: themeValidator,
    },
    round: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'theme-100',
    },
    bgColor: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: md,
      validator: size => validator(size, ...SIZES),
    },
    nativeType: {
      type: String,
      default: NATIVE_TYPES[0],
      validator: type => validator(type, ...NATIVE_TYPES),
    },
    icon: String,
    disabled: Boolean,
  },
  computed: {
    getIcon() {
      return this.icon ? `bi-${this.icon}` : '';
    },
    hasIcon() {
      return !!this.icon;
    },
    getSize() {
      return SIZES[this.size];
    },
    getClasses() {
      return [
        `getecma-button--${this.size}`,
        `color--${this.color}`,
        `bg-color--${this.bgColor}`,
      ];
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  box-shadow: inset 0 0 0px 19px transparent;
  transition: 1s;
  height: fit-content;
}
button::v-deep:hover {
  box-shadow: inset 0 0 0px 19px rgba(0, 0, 0, 0.2);
}

button.topbar {
  text-transform: capitalize;
  border: none !important;
  height: fit-content;
  padding: 6px 14px 5px 10px !important;
  color: white;
  font-size: 10px;
  border-radius: 6px;
  transition: .5s;
}

button.topbar:hover,
button.topbar:focus,
button.topbar:active {
  backdrop-filter: brightness(1.1);
  color: inherit;
}

button.topbar:focus {
  color: inherit;
}
</style>
