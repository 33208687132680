import * as vehicleResource from '@/modules/vehicle/vehicle.resource';

export function fetchVehicles(pagination = {}) {
  return new Promise((resolve, reject) =>
    vehicleResource
      .fetchVehicles(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getVehicleById(id) {
  return new Promise((resolve, reject) =>
    vehicleResource
      .getVehicleById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewVehicle(vehicle) {
  return new Promise((resolve, reject) =>
    vehicleResource
      .createNewVehicle(vehicle)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateVehicleInformation(vehicle) {
  return new Promise((resolve, reject) =>
    vehicleResource
      .updateVehicleInformation(vehicle)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeVehicle(vehicle) {
  return new Promise((resolve, reject) =>
    vehicleResource
      .removeVehicle(vehicle)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
