export const RECURRENCES_URL = Object.freeze({
  path: '/recurrences',
  view: {
    name: 'recurrence.view',
    path: '/recurrences/view/:id',
  },
  edit: {
    name: 'recurrence.edit',
    path: '/recurrences/edit/:id',
  },
  create: {
    name: 'recurrence.create',
    path: '/recurrences/create',
  },
  forbidden: {
    name: 'recurrence.forbidden',
    path: '/recurrences/forbidden',
  },
});

export const FREQUENCIES = [
  { key: '1', value: 'vez(es) por mês' },
  { key: '2', value: 'vez(es) por semana' },
];
