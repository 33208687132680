import Vue from 'vue';

import { required, email, min } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, setInteractionMode, extend, localize } from 'vee-validate';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';

localize('pt_BR', ptBR);
setInteractionMode('passive');

extend('min', min);
extend('email', email);
extend('required', required);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
