import { USERS_URL } from '@/modules/user/user.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: USERS_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...USERS_URL.view,
        component: () => import('@/modules/user/views/UserViewPage.vue'),
      },
      {
        ...USERS_URL.edit,
        component: () => import('@/modules/user/views/UserEditPage.vue'),
      },
      {
        ...USERS_URL.create,
        component: () => import('@/modules/user/views/UserEditPage.vue'),
      },
      {
        ...USERS_URL.forbidden,
        component: () => import('@/modules/user/views/UserForbiddenPage.vue'),
      },
    ],
  },
];

export function goToUserForbiddenPage($router, creating) {
  $router.push({
    name: USERS_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenUser($router, user) {
  $router.push({
    name: USERS_URL.view.name,
    params: { id: user.id },
  });
}

export function goToCreateUser($router) {
  $router.push({
    name: USERS_URL.create.name,
  });
}
