<template>
  <el-rate
    v-model="localValue"
    :disabled="disabled"
    :show-text="showText"
    :show-score="showScore"
    text-color="#ff9900"
    @change="$emit('change', localValue)" />
</template>

<script>
export default {
  name: 'GetecmaRating',
  props: {
    value: Number,
    disabled: Boolean,
    showText: Boolean,
    showScore: Boolean,
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
  },
};
</script>
<style scoped>
::v-deep .el-rate__icon {
  margin-right: 0;
}
</style>
