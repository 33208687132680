<template>
  <div :class="classes" :style="styles">
    <slot />
  </div>
</template>

<script>
import { mixinColors } from '@/mixins/colors/mixinColors';
import { mixinClasses } from '@/mixins/classes/mixinClasses';

export default {
  name: 'GetecmaSmall',
  mixins: [mixinColors, mixinClasses],
  props: {
    align: {
      type: String,
      default: 'center',
      validator(align) {
        return align && ['left', 'right', 'center'].includes(align);
      },
    },
    size: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      mixins: {
        size: 'getecma-small-',
        theme: 'getecma',
      },
    };
  },
  computed: {
    styles() {
      return this.align ? `text-align: ${this.align};` : '';
    },
  },
};
</script>
