export const CUSTOMERS_URL = Object.freeze({
  path: '/customers',
  view: {
    name: 'customer.view',
    path: '/customers/view/:id',
  },
  edit: {
    name: 'customer.edit',
    path: '/customers/edit/:id',
  },
  create: {
    name: 'customer.create',
    path: '/customers/create',
  },
  forbidden: {
    name: 'customer.forbidden',
    path: '/customers/forbidden',
  },
});

export const STATUS_CONTRACT = {
  0: 'Inativo',
  1: 'Ativo',
};

export const DAYS = {
  // 0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  // 6: 'Sábado',
};

export const STATUS_ACTIVATION = {
  0: 'Não',
  1: 'Sim',
};
