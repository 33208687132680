import { get, put, post, remove } from '@/helpers/http';

export function getEmployeeById(id) {
  return get(`/employees/${id}`);
}

export function updateEmployeeInformation(employee) {
  return put(`/employees/${employee.id}`, employee);
}

export function createNewEmployee(employee) {
  return post('/employees/', employee);
}

export function removeEmployee(employee) {
  return remove(`/employees/${employee.id}`);
}

export function fetchEmployees(pagination = {}) {
  return get('/employees', { ...pagination });
}
