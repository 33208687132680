export const mixinClasses = {
  computed: {
    classes() {
      const classes = [];

      Object.entries(this._data.mixins).forEach(([key, value]) => {
        if (this._props[key] !== '') {
          classes.push(`${value}-${this._props[key]}`);
        }
      });

      return classes;
    },
  },
};
