export const CITIES_URL = Object.freeze({
  path: '/cities',
  view: {
    name: 'city.view',
    path: '/cities/view/:id',
  },
  edit: {
    name: 'city.edit',
    path: '/cities/edit/:id',
  },
  create: {
    name: 'city.create',
    path: '/cities/create',
  },
  forbidden: {
    name: 'city.forbidden',
    path: '/cities/forbidden',
  },
});
