<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-color--theme-100-900">
        <div v-if="title" class="modal-header">
          <getecma-header size="sm" class="modal-title">{{ title }}</getecma-header>
          <button
            v-if="showCloseButton"
            class="btn-close bg-color--none-danger"
            data-bs-dismiss="modal"></button>
        </div>
        <div v-if="!fullLine" class="modal-body d-flex text-start">
          <slot name="content" />
        </div>
        <div v-else class="modal-body text-start">
          <slot name="content" />
        </div>
        <div v-if="showFooter" class="modal-footer">
          <div class="row">
            <div class="col d-flex">
              <getecma-button
                v-if="showCloseButton"
                class="p--md"
                :round="false"
                size="sm"
                @click="close">
                Cancelar
              </getecma-button>
            </div>
            <div v-if="$slots.footer" class="col d-flex">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buildModal } from '@/components/modal/modal.service';

export default {
  name: 'GetecmaModal',
  props: {
    title: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: () => true,
    },
    fullLine: {
      type: Boolean,
      default: () => true,
    },
    showFooter: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      instance: null,
    };
  },
  mounted() {
    this.create();
    this.instance.show();
    this.$emit('open');
  },
  methods: {
    create() {
      this.instance = buildModal(this.$refs.modal, {
        onHidden: this.close,
      });
    },
    close(params) {
      this.instance.dispose();
      this.$emit('close', params);
    },
  },
};
</script>
