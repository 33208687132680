import * as userStore from '@/modules/user/user.store';
import * as userResource from '@/modules/user/user.resource';

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    userResource.getCurrentUser()
      .then(({ data }) => {
        userStore.mutations.setUser(data);
        return resolve(data);
      })
      .catch(err => {
        userStore.mutations.setUser(null);
        return reject(err);
      });
  });
}

export function resolveCurrentUser(to, from, next) {
  getCurrentUser().then(() => {
    next();
  }).catch(next);
}

export function getCurrentStoredUser() {
  return userStore.getters.getUser();
}

export function fetchUsers(pagination = {}) {
  return new Promise((resolve, reject) =>
    userResource
      .fetchUsers(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getUserById(id) {
  return new Promise((resolve, reject) =>
    userResource
      .getUserById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function checkUserByEmail(email) {
  return new Promise((resolve, reject) =>
    userResource
      .checkUserByEmail(email)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewUser(user) {
  return new Promise((resolve, reject) =>
    userResource
      .createNewUser(user)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateUserInformation(user) {
  return new Promise((resolve, reject) =>
    userResource
      .updateUserInformation(user)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeUser(user) {
  return new Promise((resolve, reject) =>
    userResource
      .removeUser(user)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
