<template>
  <el-avatar
    :icon="icon"
    :src="source"
    :class="classes"
    shape="circle" />
</template>

<script>
import { validator } from '@/components/components.validators';
import { SIZES, md } from '@/components/components.constants';

const EMPTY_ICON = 'el-icon-user-solid';

export default {
  name: 'GetecmaAvatar',
  props: {
    source: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: md,
      validator: size => validator(size, ...SIZES),
    },
    icon: {
      type: String,
      default: EMPTY_ICON,
    },
  },
  computed: {
    sizes() {
      return SIZES[this.size];
    },
    classes() {
      return [`el-avatar--${this.size}`];
    },
  },
};
</script>
