import { MODELS_URL } from '@/modules/model/model.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: MODELS_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...MODELS_URL.view,
        component: () => import('@/modules/model/views/ModelViewPage.vue'),
      },
      {
        ...MODELS_URL.edit,
        component: () => import('@/modules/model/views/ModelEditPage.vue'),
      },
      {
        ...MODELS_URL.create,
        component: () => import('@/modules/model/views/ModelEditPage.vue'),
      },
      {
        ...MODELS_URL.forbidden,
        component: () => import('@/modules/model/views/ModelForbiddenPage.vue'),
      },
    ],
  },
];

export function goToModelForbiddenPage($router, creating) {
  $router.push({
    name: MODELS_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenModel($router, model) {
  $router.push({
    name: MODELS_URL.view.name,
    params: { id: model.id },
  });
}

export function goToCreateModel($router) {
  $router.push({
    name: MODELS_URL.create.name,
  });
}
