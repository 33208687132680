<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="10.5"
      cy="8.00098"
      r="5" />
    <path
      d="M19.5 10.001L19.5 16.001"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M22.5 13.001L16.5 13.001"
      stroke-width="2"
      stroke-linecap="round" />
    <path
      d="M17.6421 20.3835C18.1038 20.279 18.3806 19.799 18.176 19.3722C17.6242 18.2212 16.7173 17.2098 15.5419 16.4475C14.0955
      15.5094 12.3232 15.001 10.5 15.001C8.67681 15.001 6.90455 15.5094 5.45811 16.4475C4.28266 17.2098 3.37577 18.2212 2.82396
      19.3722C2.61935 19.799 2.89623 20.279 3.35786 20.3835C8.05976 21.4483 12.9402 21.4483 17.6421 20.3835Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'UserAddIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
