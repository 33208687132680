<template>
  <getecma-item-table-layout v-if="columns">
    <template #name>
      <getecma-p size="xs" color="theme-600-400" class="header cursor--pointer">
        {{ (columns[0]) }}
      </getecma-p>
    </template>
    <template #firstColumn>
      <getecma-container @click="orderBy(columns[1])">
        <doc-icon size="xs" fill="theme-400" class="mb--md me--xs" />
        <getecma-p size="xs" color="theme-600-400" class="header cursor--pointer me--xl" :class="selected === columns[1] ? 'selected' : ''">
          {{ (columns[1]) }}
          <i v-if="order == 'DESC'" class="el-icon-arrow-down" />
          <i v-else class="el-icon-arrow-up" />
        </getecma-p>
      </getecma-container>
    </template>
    <template #secondColumn>
      <getecma-container @click="orderBy(columns[2])">
        <doc-icon size="xs" fill="theme-400" class="mb--md me--xs" />
        <getecma-p size="xs" color="theme-600-400" class="header cursor--pointer me--xl" :class="selected === columns[2] ? 'selected' : ''">
          {{ (columns[2]) }}
          <i v-if="order == 'DESC'" class="el-icon-arrow-down" />
          <i v-else class="el-icon-arrow-up" />
        </getecma-p>
      </getecma-container>
    </template>
    <template #thirdColumn>
      <getecma-container @click="orderBy(columns[3])">
        <eye-icon size="xs" fill="theme-400" class="mb--md me--xs" />
        <getecma-p size="xs" color="theme-600-400" class="header cursor--pointer me--xl" :class="selected === columns[3] ? 'selected' : ''">
          {{ (columns[3]) }}
          <i v-if="order == 'DESC'" class="el-icon-arrow-down" />
          <i v-else class="el-icon-arrow-up" />
        </getecma-p>
      </getecma-container>
    </template>
    <template #fourthColumn>
      <getecma-container @click="orderBy(columns[4])">
        <user-icon size="xs" fill="theme-400" class="mb--md me--xs" />
        <getecma-p size="xs" color="theme-600-400" class="header cursor--pointer" :class="selected === columns[4] ? 'selected' : ''">
          {{ (columns[4]) }}
          <i v-if="order == 'DESC'" class="el-icon-arrow-down" />
          <i v-else class="el-icon-arrow-up" />
        </getecma-p>
      </getecma-container>
    </template>
  </getecma-item-table-layout>
</template>

<script>
import GetecmaItemTableLayout from '@/layouts/ItemTableLayout.vue';
import UserIcon from '@/components/icon/User.vue';
import DocIcon from '@/components/icon/Doc.vue';
import EyeIcon from '@/components/icon/Eye.vue';

export default {
  name: 'GetecmaTableHeader',
  components: {
    GetecmaItemTableLayout,
    UserIcon,
    DocIcon,
    EyeIcon,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.columns[1],
      order: 'DESC',
    };
  },
  methods: {
    invertOrder(order) {
      return order === 'ASC' ? 'DESC' : 'ASC';
    },
    orderBy(column) {
      if (this.selected === column) {
        this.order = this.invertOrder(this.order);
        this.$emit('orderBy', { by: column, order: this.order });
      } else {
        this.selected = column;
        this.$emit('orderBy', { by: column, order: this.order });
      }
    },
  },
};
</script>

<style scoped>

.header:hover {
  color: #409eff !important;
}

.selected {
  color: #409eff !important;
  filter: grayscale(0%) !important;
  opacity: 1 !important;
}
</style>
