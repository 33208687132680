export const VEHICLES_URL = Object.freeze({
  path: '/vehicles',
  view: {
    name: 'vehicle.view',
    path: '/vehicles/view/:id',
  },
  edit: {
    name: 'vehicle.edit',
    path: '/vehicles/edit/:id',
  },
  create: {
    name: 'vehicle.create',
    path: '/vehicles/create',
  },
  forbidden: {
    name: 'vehicle.forbidden',
    path: '/vehicles/forbidden',
  },
});
