import Vue from 'vue';
import GetecmaLayout from '@/layouts/Layout.vue';
import GetecmaIcon from '@/components/icon/Icon.vue';
import GetecmaForm from '@/components/form/Form.vue';
import GetecmaInput from '@/components/input/Input.vue';
import GetecmaTextarea from '@/components/input/Textarea.vue';
import GetecmaNavbar from '@/components/navbar/Navbar.vue';
import GetecmaPagination from '@/components/pagination/Pagination.vue';
import GetecmaSelect from '@/components/select/Select.vue';
import GetecmaTable from '@/components/table/Table.vue';
import GetecmaTagInput from '@/components/tag-input/TagInput.vue';
import GetecmaModal from '@/components/modal/Modal.vue';
import GetecmaModalConfirm from '@/components/modal/ModalConfirm.vue';
import GetecmaModalDelete from '@/components/modal/ModalDelete.vue';
import GetecmaModalChangePassword from '@/components/modal/ModalChangePassword.vue';
import GetecmaModalAddCollect from '@/components/modal/ModalAddCollect.vue';
import GetecmaModalEditCollect from '@/components/modal/ModalEditCollect.vue';
import GetecmaModalGenerateCollects from '@/components/modal/ModalGenerateCollects.vue';
import GetecmaModalInfo from '@/components/modal/ModalInfo.vue';
import GetecmaAvatar from '@/components/avatar/Avatar.vue';
import GetecmaTag from '@/components/tag/Tag.vue';
import GetecmaInvisible from '@/components/invisible/Invisible.vue';
import GetecmaImage from '@/components/image/Image.vue';
import GetecmaButton from '@/components/button/Button.vue';
import GetecmaRating from '@/components/rating/Rating.vue';
import GetecmaEmpty from '@/components/empty/Empty.vue';
import GetecmaTopbar from '@/modules/topbar/Topbar.vue';
import GetecmaEmptyBox from '@/components/empty/EmptyBox.vue';

// Containers
import GetecmaRow from '@/components/container/Row.vue';
import GetecmaCol from '@/components/container/Column.vue';
import GetecmaContainer from '@/components/container/Container.vue';

// Typography
import GetecmaP from '@/components/typography/Paragraph.vue';
import GetecmaSmall from '@/components/typography/Small.vue';
import GetecmaHeader from '@/components/typography/Header.vue';

const components = {
  GetecmaLayout,
  GetecmaIcon,
  GetecmaForm,
  GetecmaInput,
  GetecmaTextarea,
  GetecmaNavbar,
  GetecmaTopbar,
  GetecmaPagination,
  GetecmaSelect,
  GetecmaTable,
  GetecmaTagInput,
  GetecmaModal,
  GetecmaModalConfirm,
  GetecmaModalDelete,
  GetecmaModalChangePassword,
  GetecmaModalAddCollect,
  GetecmaModalEditCollect,
  GetecmaModalGenerateCollects,
  GetecmaModalInfo,
  GetecmaAvatar,
  GetecmaTag,
  GetecmaInvisible,
  GetecmaImage,
  GetecmaButton,
  GetecmaRating,
  GetecmaEmpty,
  GetecmaEmptyBox,

  // Containers
  GetecmaRow,
  GetecmaCol,
  GetecmaContainer,

  // Typography
  GetecmaP,
  GetecmaSmall,
  GetecmaHeader,
};

Object.entries(components).forEach(([name, component]) => Vue.component(name, component));
