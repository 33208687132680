export const mixinColors = {
  data() {
    return {
      mixins: {
        color: 'color-',
        bgColor: 'bg-color-',
      },
    };
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: '',
    },
  },
};
