<template>
  <getecma-invisible>
    <getecma-row class="mb--md d-flex justify-content-center justify-content--space-between align-items-center">
      <getecma-col :xs="24" :span="showRangeDate ? 12 : 24" class="mb--sm">
        <getecma-header size="md" class="mt--md d-flex align-items-center">
          {{ label }} <getecma-small v-if="!showRangeDate && type == 'track'" class="ms--md fw--strong mb--none">(Todos os períodos)</getecma-small>
        </getecma-header>
      </getecma-col>

      <getecma-col v-if="showRangeDate" :xs="24" :span="13" class="mb--sm d-flex justify-content-center">
        <getecma-invisible class="me--lg d-flex justify-content-center align-items-center">
          <getecma-p color="theme-900-300" class="me--md mb--0 mt--md" size="md">De:</getecma-p>
          <getecma-input v-model="startDate" type="date" name="data inicial" rules="" @change="fetch()" />
        </getecma-invisible>
        <getecma-invisible class="d-flex justify-content-center align-items-center">
          <getecma-p color="theme-900-300" class="me--md mt--md" size="md">Até:</getecma-p>
          <getecma-input v-model="endDate" type="date" name="data final" rules="" @change="fetch()" />
        </getecma-invisible>
        <div
          class="ms--md cursor--pointer d-flex justify-content-center align-items-center"
          name="download"
          @click="downloadCSV">
          <el-tooltip
            effect="dark"
            content="Baixar CSV"
            placement="top"
            class="px--xl py--sm bg-color--theme-100 rounded">
            <i class="el-icon-download" />
          </el-tooltip>
        </div>
      </getecma-col>
    </getecma-row>
  </getecma-invisible>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getMonthRange } from '@/services/timeService';

export default {
  name: 'GetecmaTableLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    showRangeDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      date: new Date(),
      startDate: '',
      endDate: '',
    };
  },
  created() {
    this.buildRangeDate();
  },
  methods: {
    fetch() {
      if (this.startDate >= this.endDate) {
        toastError('A data inicial deve ser menor que a data final');
        return;
      }
      this.$emit('fetch', [`${this.startDate}T00:00:00`, `${this.endDate}T23:59:59`]);
    },
    downloadCSV() {
      if (this.startDate >= this.endDate) {
        toastError('A data inicial deve ser menor que a data final');
        return;
      }
      this.$emit('download', [`${this.startDate}T00:00:00`, `${this.endDate}T23:59:59`]);
    },
    buildRangeDate() {
      const { start, end } = getMonthRange();
      this.startDate = start;
      this.endDate = end;
    },
  },
};
</script>

<style scoped>
  .gray-squared-button {
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 5px;
    color: rgb(161, 161, 161);
    background-color: #f4f4f5 !important;
  }
  .gray-squared-button:hover {
    background-color: #f0f0f0 !important;
  }
</style>
