export const NEIGHBORHOODS_URL = Object.freeze({
  path: '/neighborhoods',
  view: {
    name: 'neighborhood.view',
    path: '/neighborhoods/view/:id',
  },
  edit: {
    name: 'neighborhood.edit',
    path: '/neighborhoods/edit/:id',
  },
  create: {
    name: 'neighborhood.create',
    path: '/neighborhoods/create',
  },
  forbidden: {
    name: 'neighborhood.forbidden',
    path: '/neighborhoods/forbidden',
  },
});
