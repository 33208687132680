<template>
  <div class="d-flex h-100 align-items-center justify-content-between d-xs-flex-row flex-column">
    <div class="getecma-menu d-flex d-xs-flex-row flex-column">
      <getecma-menu-item route="/" title="Dashboard">
        <el-tooltip effect="dark" content="Dashboard" placement="right">
          <home-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item v-if="isAdmin" :route="MY_SPACE_URL.users.path" title="Usuários">
        <el-tooltip effect="dark" content="Usuários" placement="right">
          <user-add-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.capacities.path" title="Unidades de Medida">
        <el-tooltip effect="dark" content="Unidades de Medida" placement="right">
          <capacity-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.models.path" title="Modelos">
        <el-tooltip effect="dark" content="Modelos" placement="right">
          <truck-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.vehicles.path" title="Veículos">
        <el-tooltip effect="dark" content="Veículos" placement="right">
          <license-plate-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.cities.path" title="Cidades">
        <el-tooltip effect="dark" content="Cidades" placement="right">
          <city-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.neighborhoods.path" title="Bairros">
        <el-tooltip effect="dark" content="Bairros" placement="right">
          <neighborhood-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.regions.path" title="Rotas">
        <el-tooltip effect="dark" content="Rotas" placement="right">
          <regions-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.employees.path" title="Funcionários">
        <el-tooltip effect="dark" content="Funcionários" placement="right">
          <employee-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.recurrences.path" title="Recorrências">
        <el-tooltip effect="dark" content="Recorrências" placement="right">
          <recurrence-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.customers.path" title="Clientes">
        <el-tooltip effect="dark" content="Clientes" placement="right">
          <customer-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="MY_SPACE_URL.groups.path" title="Grupos">
        <el-tooltip effect="dark" content="Grupos" placement="right">
          <group-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-menu-item :route="SETTINGS_URL.default.path" title="Configurações">
        <el-tooltip effect="dark" content="Configurações" placement="right">
          <setting-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
      <getecma-divider :width="50" class="mt--lg" />
      <getecma-menu-item title="Sair" @click="onLogout">
        <el-tooltip effect="dark" content="Sair" placement="right">
          <shutdown-icon size="sm" />
        </el-tooltip>
      </getecma-menu-item>
    </div>
    <getecma-darkmode />
  </div>
</template>

<script>
import GetecmaDarkmode from '@/components/darkmode/Darkmode.vue';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';
import { SETTINGS_URL } from '@/modules/setting/setting.constants';
import { removeCookie } from '@/helpers/cookies/cookie';
import { goToLoginPage } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { AUTH_COOKIE_NAME } from '@/modules/auth/auth.constants';

import HomeIcon from '../icon/Home.vue';
import UserAddIcon from '../icon/UserAdd.vue';
import LicensePlateIcon from '../icon/LicensePlate.vue';
import TruckIcon from '../icon/Truck.vue';
import CityIcon from '../icon/City.vue';
import NeighborhoodIcon from '../icon/Neighborhood.vue';
import RegionsIcon from '../icon/Regions.vue';
import EmployeeIcon from '../icon/Employee.vue';
import CustomerIcon from '../icon/Customer.vue';
import RecurrenceIcon from '../icon/Recurrence.vue';
import CapacityIcon from '../icon/Capacity.vue';
import GroupIcon from '../icon/Group.vue';
import SettingIcon from '../icon/Setting.vue';
import ShutdownIcon from '../icon/Shutdown.vue';
import GetecmaMenuItem from './components/MenuItem.vue';
import GetecmaDivider from './components/Divider.vue';

export default {
  name: 'GetecmaMenu',
  components: {
    HomeIcon,
    UserAddIcon,
    LicensePlateIcon,
    TruckIcon,
    CityIcon,
    NeighborhoodIcon,
    RegionsIcon,
    EmployeeIcon,
    CustomerIcon,
    RecurrenceIcon,
    CapacityIcon,
    GroupIcon,
    SettingIcon,
    ShutdownIcon,
    GetecmaDarkmode,
    GetecmaMenuItem,
    GetecmaDivider,
  },
  data() {
    return {
      isCollapse: true,
      MY_SPACE_URL,
      SETTINGS_URL,
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  methods: {
    onLogout() {
      removeCookie(AUTH_COOKIE_NAME);
      goToLoginPage();
    },
  },
};
</script>
