<template>
  <p :class="classes">
    <slot />
  </p>
</template>

<script>
import { mixinColors } from '@/mixins/colors/mixinColors';
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { SIZES } from '@/components/components.constants';

const mixinColorsParagraph = JSON.parse(JSON.stringify(mixinColors));
mixinColorsParagraph.props.color.default = 'theme-900-100';
mixinColorsParagraph.data = mixinColors.data;

export default {
  name: 'GetecmaParagraph',
  mixins: [mixinColorsParagraph, mixinClasses],
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'lg',
      validator(size) {
        return size && SIZES.includes(size);
      },
    },
    margin: {
      type: String,
      default: 'md',
      validator(value) {
        return value && ['none', ...SIZES].includes(value);
      },
    },
    weight: {
      type: String,
      default: 'regular',
      validator(value) {
        return value && ['regular', 'strong', 'strongest'].includes(value);
      },
    },
    display: {
      type: String,
      default: 'block',
    },
  },
  data() {
    return {
      mixins: {
        size: 'fs-',
        margin: 'mb-',
        weight: 'fw-',
        display: 'd-',
      },
    };
  },
};
</script>
