<template>
  <getecma-container>
    <router-link :to="HOME_URL.path">
      <img src="@/assets/images/logo-vert.png" style="width:180px!important" />
    </router-link>
  </getecma-container>
</template>
<script>

import { HOME_URL } from '@/modules/home/home.constants';

import GetecmaContainer from '@/components/container/Container.vue';

export default {
  name: 'GetecmaTopbar',
  components: {
    GetecmaContainer,
  },
  data() {
    return {
      HOME_URL,
      width: this.size,
    };
  },
};
</script>
