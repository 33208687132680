<template>
  <getecma-modal
    ref="modal"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="content" v-html="content" />
    </template>
    <template #footer>
      <getecma-button
        class="p--md"
        size="sm"
        :round="false"
        bg-color="success"
        @click="confirm()">
        Confirmar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
export default {
  name: 'GetecmaModalConfirm',
  props: {
    closeAfterConfirm: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'warning',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      if (this.closeAfterConfirm) {
        this.$refs.modal.close();
      }
    },
  },
};
</script>
