export const GROUPS_URL = Object.freeze({
  path: '/groups',
  view: {
    name: 'group.view',
    path: '/groups/view/:id',
  },
  edit: {
    name: 'group.edit',
    path: '/groups/edit/:id',
  },
  create: {
    name: 'group.create',
    path: '/groups/create',
  },
  forbidden: {
    name: 'group.forbidden',
    path: '/groups/forbidden',
  },
});
