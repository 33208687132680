<template>
  <getecma-container :direction="direction">
    <slot />
  </getecma-container>
</template>
<script>

import GetecmaContainer from '@/components/container/Container.vue';

export default {
  name: 'Topbar',
  components: {
    GetecmaContainer,
  },
  props: {
    direction: {
      type: String,
      default: 'column',
    },
  },
};
</script>
<style lang="scss">
  @import './Topbar.scss';
</style>
