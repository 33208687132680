<template>
  <svg :class="classes"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 612 612"
    xml:space="preserve">
      <path
        d="M34.533,390.596c-10.214,8.754-17.231,21.053-19.316,35.02H6.949c-3.822,0-6.949-3.127-6.949-6.949v-21.123
   c0-3.822,3.126-6.947,6.949-6.947H34.533L34.533,390.596z M114.159,425.615c0.556,2.709,0.834,5.418,0.834,8.268
   c0,23.832-19.316,43.217-43.218,43.217c-23.832,0-43.218-19.385-43.218-43.217c0-2.85,0.277-5.559,0.833-8.268
   c3.821-19.943,21.331-35.02,42.384-35.02S110.407,405.672,114.159,425.615z M93.384,433.883c0-2.918-0.555-5.768-1.667-8.268
   c-3.196-7.852-10.909-13.342-19.941-13.342c-9.033,0-16.745,5.49-19.942,13.342c-1.112,2.5-1.667,5.35-1.667,8.268
   c0,11.881,9.658,21.609,21.609,21.609C83.727,455.492,93.384,445.764,93.384,433.883z M17.106,362.873V155.745
   c0-11.512,9.333-20.845,20.845-20.845h332.125c11.513,0,20.845,9.333,20.845,20.845v207.128c0,3.82-3.126,6.947-6.948,6.947H24.055
   C20.233,369.82,17.106,366.693,17.106,362.873z M340.476,339.488c0,3.299,2.674,5.973,5.973,5.973h0.186
   c3.298,0,5.972-2.674,5.972-5.973V167.625c0-3.298-2.674-5.972-5.972-5.972h-0.186c-3.299,0-5.973,2.674-5.973,5.972V339.488z
    M284.314,339.488c0,3.299,2.673,5.973,5.971,5.973h0.186c3.299,0,5.972-2.674,5.972-5.973V167.625
   c0-3.298-2.673-5.972-5.972-5.972h-0.186c-3.298,0-5.971,2.674-5.971,5.972V339.488z M228.151,339.488
   c0,3.299,2.674,5.973,5.972,5.973h0.186c3.298,0,5.972-2.674,5.972-5.973V167.625c0-3.298-2.674-5.972-5.972-5.972h-0.186
   c-3.298,0-5.972,2.674-5.972,5.972V339.488z M171.99,339.488c0,3.299,2.674,5.973,5.972,5.973h0.186
   c3.298,0,5.972-2.674,5.972-5.973V167.625c0-3.298-2.674-5.972-5.972-5.972h-0.186c-3.298,0-5.972,2.674-5.972,5.972V339.488z
    M115.828,339.488c0,3.299,2.673,5.973,5.972,5.973h0.186c3.298,0,5.971-2.674,5.971-5.973V167.625
   c0-3.298-2.673-5.972-5.971-5.972H121.8c-3.299,0-5.972,2.674-5.972,5.972V339.488z M59.666,339.488
   c0,3.299,2.674,5.973,5.972,5.973h0.186c3.299,0,5.972-2.674,5.972-5.973V167.625c0-3.298-2.673-5.972-5.972-5.972h-0.186
   c-3.298,0-5.972,2.674-5.972,5.972V339.488z M220.815,425.615c0.556,2.709,0.834,5.418,0.834,8.268
   c0,23.832-19.316,43.217-43.218,43.217c-23.833,0-43.218-19.385-43.218-43.217c0-2.85,0.278-5.559,0.833-8.268
   c3.822-19.943,21.332-35.02,42.385-35.02S217.063,405.672,220.815,425.615z M200.04,433.883c0-2.918-0.556-5.768-1.598-8.268
   c-3.266-7.852-10.979-13.342-20.011-13.342s-16.746,5.49-20.011,13.342c-1.042,2.5-1.598,5.35-1.598,8.268
   c0,11.881,9.658,21.609,21.61,21.609C190.382,455.492,200.04,445.764,200.04,433.883z M141.188,390.596h-32.169
   c7.087,6.113,12.715,13.965,16.12,22.859C128.473,404.562,134.101,396.709,141.188,390.596z M612,397.543v21.123
   c0,3.822-3.127,6.949-6.948,6.949h-56.28c-4.03-27.586-27.724-48.916-56.42-48.916s-52.459,21.33-56.489,48.916H234.989
   c-2.084-13.967-9.102-26.266-19.316-35.02H410.78V191.876c0-7.644,6.183-13.897,13.896-13.897h65.592
   c18.482,0,35.714,9.172,46.065,24.458l42.315,62.673c6.184,9.172,9.519,20.011,9.519,31.058v94.427h16.884
   C608.873,390.596,612,393.721,612,397.543z M541.545,266.708l-33.838-48.082c-1.32-1.806-3.404-2.918-5.697-2.918h-52.736
   c-3.821,0-6.949,3.126-6.949,6.949v48.012c0,3.891,3.128,6.948,6.949,6.948h86.574
   C541.477,277.617,544.741,271.294,541.545,266.708z M535.569,433.883c0,23.832-19.385,43.217-43.218,43.217
   c-23.902,0-43.218-19.385-43.218-43.217c0-23.902,19.316-43.287,43.218-43.287C516.185,390.596,535.569,409.98,535.569,433.883z
    M513.961,433.883c0-11.951-9.658-21.609-21.609-21.609s-21.609,9.658-21.609,21.609c0,11.881,9.658,21.609,21.609,21.609
   S513.961,445.764,513.961,433.883z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'TruckIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
