<template>
  <div class="d--flex direction--column align-items--center justify-content-center py--xxl">
    <div style="width:160px">
      <svg viewBox="0 0 79 86" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs><rect id="path-3-6" x="0" y="0" width="17" height="36">
        </rect></defs><g id="Illustrations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="B-type" transform="translate(-1268.000000, -535.000000)"><g id="Group-2" transform="translate(1268.000000, 535.000000)">
        <path id="Oval-Copy-2" d="M39.5,86 C61.3152476,86 79,83.9106622 79,81.3333333 C79,78.7560045 57.3152476,78 35.5,78 C13.6847524,78
        0,78.7560045 0,81.3333333 C0,83.9106622 17.6847524,86 39.5,86 Z" fill="" :class="`fill--${colors.gray3}`" opacity=".4"></path>
        <polygon id="Rectangle-Copy-14" fill="" :class="`fill--${colors.gray2}`" transform="translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000)"
        points="13 58 53 58 42 45 2 45"></polygon><g id="Group-Copy" transform="translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000)
        translate(-34.500000, -31.500000) translate(7.000000, 10.000000)"><polygon id="Rectangle-Copy-10" fill="" :class="`fill--${colors.gray3}`"
        transform="translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) " points="2.84078316e-14 3 18 3 23 7 5 7">
        </polygon><polygon id="Rectangle-Copy-11" fill="" :class="`fill--${colors.gray2}`" points="-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43"></polygon>
        <rect id="Rectangle-Copy-12" fill="" :class="`fill--${colors.gray1}`" transform="translate(46.500000, 25.000000) scale(-1, 1)
        translate(-46.500000, -25.000000) " x="38" y="7" width="17" height="36"></rect><polygon id="Rectangle-Copy-13"
        fill="" :class="`fill--${colors.gray2}`" transform="translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000)"
        points="24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"></polygon></g><rect id="Rectangle-Copy-15" fill="" :class="`fill--${colors.gray1}`"
        x="13" y="45" width="40" height="36"></rect><g id="Rectangle-Copy-17" transform="translate(53.000000, 45.000000)">
        <use id="Mask" fill="" :class="`fill--${colors.gray3}`"
        transform="translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) " xlink:href="#path-3-6"></use>
          </g><polygon id="Rectangle-Copy-18"
        fill="" :class="`fill--${colors.gray2}`" transform="translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) " points="62 45 79 45 70 58 53 58">
        </polygon></g></g></g>
      </svg>
    </div>
    <p class="mt--xxl">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'GetecmaEmptyBox',
  props: {
    description: {
      type: String,
      default: 'No data to be displayed!',
    },
  },
  data() {
    return {
      colors: {
        gray1: 'gray1-500',
        gray2: 'gray2-600',
        gray3: 'gray3-650',
      },
    };
  },
};
</script>
