export const mixinContainer = {
  data() {
    return {
      mixins: {
        position: 'position',
        display: 'd',
        grow: 'flex-grow',
        alignItems: 'align-items',
        justifyContent: 'justify-content',
        direction: 'flex',
      },
    };
  },
  props: {
    position: {
      type: String,
      default: 'relative',
    },
    display: {
      type: String,
      default: 'flex',
    },
    grow: {
      type: String,
      default: '',
    },
    alignItems: {
      type: String,
      default: '',
    },
    justifyContent: {
      type: String,
      default: 'center',
    },
    direction: {
      type: String,
      default: 'row',
    },
  },
};
