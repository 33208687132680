import { SIZES, lg } from '@/components/components.constants';
import { validator } from '@/components/components.validators';

export const mixinIcon = {
  data() {
    return {
      mixins: {
        size: 'icon-',
      },
    };
  },
  props: {
    size: {
      type: String,
      default: lg,
      validator: size => validator(size, ...SIZES),
    },
  },
};
