export const mixinSvg = {
  data() {
    return {
      mixins: {
        fill: 'fill-',
        stroke: 'stroke-',
      },
    };
  },
  props: {
    fill: {
      type: String,
      default: 'theme-300',
    },
    stroke: {
      type: String,
      default: 'transparent',
    },
  },
};
