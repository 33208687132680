<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 2.00098V7.00098L12.5 7.05538C12.4999 7.47945 12.4998 7.89158 12.5455 8.2315C12.597 8.6147 12.7226 9.05197 13.0858
      9.41519C13.449 9.77841 13.8863 9.90393 14.2695 9.95545C14.6094 10.0011 15.0215 10.0011 15.4456 10.001H15.4456H15.4456H15.4456L15.5
      10.001H20.5V16.001C20.5 18.8294 20.5 20.2436 19.6213 21.1223C18.7426 22.001 17.3284 22.001 14.5 22.001H10.5C7.67157 22.001 6.25736
      22.001 5.37868 21.1223C4.5 20.2436 4.5 18.8294 4.5 16.001V8.00098C4.5 5.17255 4.5 3.75834 5.37868 2.87966C6.25736 2.00098 7.67157
      2.00098 10.5 2.00098H12.5ZM14.5 2.0056V7.00098C14.5 7.50065 14.5021 7.77481 14.5277 7.965L14.5287 7.97228L14.536 7.97328C14.7262
      7.99885 15.0003 8.00098 15.5 8.00098H20.4954C20.4852 7.58933 20.4525 7.31693 20.3478 7.06404C20.1955 6.69649 19.9065 6.40746 19.3284
      5.8294L16.6716 3.17255C16.0935 2.59449 15.8045 2.30546 15.4369 2.15322C15.184 2.04847 14.9116 2.01579 14.5 2.0056ZM8.5 13.001C8.5
      12.4487 8.94772 12.001 9.5 12.001L15.5 12.001C16.0523 12.001 16.5 12.4487 16.5 13.001C16.5 13.5533 16.0523 14.001 15.5 14.001L9.5
      14.001C8.94772 14.001 8.5 13.5533 8.5 13.001ZM9.5 16.001C8.94772 16.001 8.5 16.4487 8.5 17.001C8.5 17.5533 8.94772 18.001 9.5
      18.001H13.5C14.0523 18.001 14.5 17.5533 14.5 17.001C14.5 16.4487 14.0523 16.001 13.5 16.001H9.5Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'DocIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
