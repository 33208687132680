export const REGIONS_URL = Object.freeze({
  path: '/regions',
  view: {
    name: 'region.view',
    path: '/regions/view/:id',
  },
  edit: {
    name: 'region.edit',
    path: '/regions/edit/:id',
  },
  create: {
    name: 'region.create',
    path: '/regions/create',
  },
  forbidden: {
    name: 'region.forbidden',
    path: '/regions/forbidden',
  },
});
