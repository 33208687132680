<template>
<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">

<g fill="#null">

<path d="M0 0v16h7.63a10.651 10.651 0 0 0-1.218-5.794c-.938-1.81-2.047-3.59-2.375-5.638C3.764 3.06 3.82 1.511 4.039 0h-4.04z" fill-opacity=".75" />

<path d="M15.999 4.746 5.817 9.076c.916 1.62 1.699 3.379 1.806 5.262.041.553.043 1.109.006 1.662H16V4.746z" fill-opacity=".25" />

<path d="M4.039 0c-.348 2.284-.252 4.69.668 6.836.318.772.712 1.509 1.111 2.24L16 4.746V0H4.04z" fill-opacity=".5" />

<path d="M0 0v16h16V0H0zm1 1h14v14H1V1z" />

</g>

</svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'RegionsIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
