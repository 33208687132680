<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.47924 10.2719C4.86454 8.19906 7.76851 5.00098 12.5 5.00098C17.2314 5.00098 20.1354 8.19905 21.5207
      10.2719C21.9855 10.9675 22.218 11.3153 22.1968 11.9579C22.1757 12.6005 21.9088 12.9479 21.3752
      13.6427C19.7861 15.7117 16.6129 19.001 12.5 19.001C8.38699 19.001 5.21384 15.7117 3.62471 13.6427C3.09106
      12.9479 2.82424 12.6005 2.80308 11.9579C2.78193 11.3153 3.01436 10.9675 3.47924 10.2719ZM12.4999
      16.001C14.7091 16.001 16.4999 14.2101 16.4999 12.001C16.4999 9.79184 14.7091 8.00098 12.4999
      8.00098C10.2908 8.00098 8.49995 9.79184 8.49995 12.001C8.49995 14.2101 10.2908 16.001 12.4999 16.001Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'EyeIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
