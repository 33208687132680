export const CAPACITIES_URL = Object.freeze({
  path: '/capacities',
  view: {
    name: 'capacity.view',
    path: '/capacities/view/:id',
  },
  edit: {
    name: 'capacity.edit',
    path: '/capacities/edit/:id',
  },
  create: {
    name: 'capacity.create',
    path: '/capacities/create',
  },
  forbidden: {
    name: 'capacity.forbidden',
    path: '/capacities/forbidden',
  },
});

export const TYPE_CAPACITY = {
  0: 'Coleta',
  1: 'Veículo',
};
