<template>
  <getecma-modal
    ref="modal"
    title="Atenção"
    :show-close-button="showCloseButton"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div v-if="content">
        {{ content }}
      </div>
      <div v-else>
        Você tem certeza que deseja excluir este item?
      </div>
      <getecma-p v-if="subtitle" size="md" class="mt--md" color="info">{{ subtitle }}</getecma-p>
    </template>
    <template #footer>
      <getecma-button
        class="btn"
        bg-color="success"
        size="sm"
        @click="confirm()">
        Confirmar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
export default {
  name: 'GetecmaModalInfo',
  props: {
    closeAfterConfirm: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      if (this.closeAfterConfirm) {
        this.$refs.modal.close();
      }
    },
  },
};
</script>
