<template>
  <i
    v-bind="$attrs"
    :class="classes"
    :style="styles"
    @click="onClick">
  </i>
</template>

<script>
import { SIZES, md, base } from '@/components/components.constants';
import { validator, themeValidator } from '@/components/components.validators';

export default {
  name: 'GetecmaIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: md,
      validator: size => validator(size, ...SIZES),
    },
    theme: {
      type: String,
      default: base,
      validator: themeValidator,
    },
    fontSize: String,
  },
  computed: {
    classes() {
      return [
        'el-icon',
        `el-icon--${this.size}`,
        `el-icon--${this.theme}`,
        {
          [`bi-${this.icon}`]: !!this.icon,
          'el-icon--link': !!this.$listeners.click,
        },
      ];
    },
    styles() {
      return this.fontSize ? `font-size: ${this.fontSize}px!important` : '';
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
