import * as groupResource from '@/modules/group/group.resource';

export function fetchGroups(pagination = {}) {
  return new Promise((resolve, reject) =>
    groupResource
      .fetchGroups(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchGroupsByCapacity(idCapacity) {
  return new Promise((resolve, reject) =>
    groupResource
      .fetchGroupsByCapacity(idCapacity)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchCustomersByGroup(idGroup) {
  return new Promise((resolve, reject) =>
    groupResource
      .fetchCustomersByGroup(idGroup)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getGroupById(id) {
  return new Promise((resolve, reject) =>
    groupResource
      .getGroupById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewGroup(group) {
  return new Promise((resolve, reject) =>
    groupResource
      .createNewGroup(group)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateGroupInformation(group) {
  return new Promise((resolve, reject) =>
    groupResource
      .updateGroupInformation(group)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeGroup(group) {
  return new Promise((resolve, reject) =>
    groupResource
      .removeGroup(group)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
