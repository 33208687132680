import { get, put, post, remove } from '@/helpers/http';

export function getCustomersById(id) {
  return get(`/customers/${id}`);
}

export function updateCustomerInformation(customer) {
  return put(`/customers/${customer.id}`, customer);
}

export function createNewCustomer(customer) {
  return post('/customers/', customer);
}

export function removeCustomer(customer) {
  return remove(`/customers/${customer.id}`);
}

export function fetchCustomers(pagination = {}) {
  return get('/customers', { ...pagination });
}

export function fetchCustomersCollects(pagination = {}) {
  return get('/customers-collects', { ...pagination });
}

export function fetchAllCustomersPerActivation() {
  return get('/customers-per-activation');
}
