<template>
  <getecma-container direction="column" class="min-vh-100" justify-content="start">
    <getecma-topbar />
    <getecma-container grow="1" class="d-xs-flex-column-reverse">
      <getecma-navbar bg-color="theme-300-900">
        <getecma-menu />
      </getecma-navbar>
      <getecma-container :align-items="alignItens" :justify-content="justifyContent" grow="1" :direction="direction" class="main">
        <slot />
      </getecma-container>
    </getecma-container>
  </getecma-container>
</template>

<script>
import GetecmaMenu from '@/components/menu/Menu.vue';

import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';

export default {
  name: 'GetecmaLayout',
  components: {
    GetecmaMenu,
  },
  props: {
    justifyContent: {
      type: String,
      default: 'center',
    },
    routerPlusIcon: {
      type: String,
      default: '', // TODO: add default link
    },
    alignItens: {
      type: String,
      default: 'center',
    },
    direction: {
      type: String,
      default: 'column',
    },
  },
  data() {
    return {
      toolTipText: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.setTootipText();
  },
  methods: {
    setTootipText() {
      this.toolTipText = '';
    },
  },
};
</script>
