<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>

import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinContainer } from '@/mixins/container/mixinContainer';
import { mixinColors } from '@/mixins/colors/mixinColors';

export default {
  name: 'GetecmaContainer',
  mixins: [mixinContainer, mixinClasses, mixinColors],
  inheritAttrs: false,
};
</script>
