import * as employeeResource from '@/modules/employee/employee.resource';

export function fetchEmployees(pagination = {}) {
  return new Promise((resolve, reject) =>
    employeeResource
      .fetchEmployees(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getEmployeeById(id) {
  return new Promise((resolve, reject) =>
    employeeResource
      .getEmployeeById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewEmployee(employee) {
  return new Promise((resolve, reject) =>
    employeeResource
      .createNewEmployee(employee)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateEmployeeInformation(employee) {
  return new Promise((resolve, reject) =>
    employeeResource
      .updateEmployeeInformation(employee)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeEmployee(employee) {
  return new Promise((resolve, reject) =>
    employeeResource
      .removeEmployee(employee)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
