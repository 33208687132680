<template>
  <topbar direction="column">
    <el-header id="#getecma-header">
      <getecma-container align-items="center" justify-content="between">
        <topbar-logo v-if="showBrand" class="ps--lg" />
        <getecma-container align-items="center">
          <getecma-topbar-user-dropdown />
        </getecma-container>
      </getecma-container>
    </el-header>
  </topbar>
</template>
<script>

import Topbar from '@/components/topbar/Topbar.vue';
import GetecmaContainer from '@/components/container/Container.vue';
import GetecmaTopbarUserDropdown from '@/modules/topbar/components/TopbarUserDropdown.vue';
import TopbarLogo from './components/TopbarLogo.vue';

export default {
  name: 'GetecmaTopbar',
  components: {
    Topbar,
    TopbarLogo,
    GetecmaContainer,
    GetecmaTopbarUserDropdown,
  },
  props: {
    showBrand: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
