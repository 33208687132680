<template>
  <getecma-modal
    ref="modal"
    title="Altere sua senha"
    :show-close-button="false"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div>
        <div class="mb--lg">
          Você deve escolher uma nova senha
        </div>
        <getecma-input
          v-model="password"
          autocomplete="off"
          type="password"
          placeholder="Digite sua nova senha" />
        <br>
        <getecma-input
          v-model="confirm_password"
          type="password"
          autocomplete="off"
          placeholder="Confirme sua nova senha" />
        <getecma-small class="d-flex mt--md" align="left">*Sua senha deve possuir pelo menos 8 caracteres</getecma-small>
        <getecma-small class="d-flex" align="left">*Sua senha deve possuir números e caracteres especiais</getecma-small>
      </div>
    </template>
    <template #footer>
      <getecma-button
        class="btn btn-primary"
        size="sm"
        @click="confirm()">
        Atualizar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
import { updateUserInformation } from '@/modules/user/user.service';
import { toastError, toastSuccess } from '@/services/toastService';

export default {
  name: 'GetecmaChangePasswordModal',
  inject: ['userEditVm'],
  data() {
    return {
      password: null,
      confirm_password: null,
    };
  },
  methods: {
    confirm() {
      this.userEditVm.user.password = this.password;
      this.userEditVm.user.password2 = this.confirm_password;

      const specialChars = !!(this.password.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+/) && this.password.match(/\d+/));

      if (!this.password) {
        toastError('Você precisa escolher uma nova senha');
      } else if (!specialChars) {
        toastError('Sua senha deve possuir números e caracteres especiais');
      } else if (this.password.length < 8) {
        toastError('Sua senha deve possuir pelo menos 8 caracteres');
      } else if (this.password !== this.confirm_password) {
        toastError('As senhas não coincidem');
      } else {
        updateUserInformation(this.userEditVm.user)
          .then(() => {
            this.$refs.modal.close();
            this.userEditVm.user.verified = true;
            toastSuccess('Senha do usuário atualizada!');
          })
          .catch(() => toastError('Erro ao atualizar a senha do usuário'));
      }
    },
  },
};
</script>
