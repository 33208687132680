<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4838 2.54259C14.5711 2.71191 14.5928 2.92874 14.6361 3.36242C14.7182 4.18328 14.7593 4.59371 14.9311 4.8189C15.149
      5.10456 15.5034 5.25136 15.8595 5.20345C16.1402 5.16569 16.4594 4.9045 17.0979 4.38211C17.4352 4.10613 17.6038 3.96813
      17.7853 3.91016C18.0158 3.83652 18.2652 3.84896 18.4872 3.94517C18.662 4.02091 18.8161 4.175 19.1243 4.48318L20.0178
      5.37664C20.3259 5.68483 20.48 5.83892 20.5558 6.01373C20.652 6.23575 20.6644 6.48513 20.5908 6.71562C20.5328 6.8971 20.3948
      7.06576 20.1188 7.40308C19.5964 8.04157 19.3352 8.36082 19.2975 8.64153C19.2496 8.9976 19.3964 9.35199 19.682 9.5699C19.9072
      9.7417 20.3176 9.78274 21.1385 9.86483C21.5722 9.9082 21.7891 9.92988 21.9584 10.0172C22.1734 10.1281 22.341 10.3133 22.4299
      10.5383C22.5 10.7154 22.5 10.9334 22.5 11.3692V12.6329C22.5 13.0686 22.5 13.2865 22.43 13.4636C22.341 13.6887 22.1734 13.8739
      21.9583 13.9848C21.789 14.0721 21.5722 14.0938 21.1386 14.1371L21.1386 14.1371C20.318 14.2192 19.9077 14.2602 19.6825
      14.4319C19.3967 14.6498 19.2499 15.0044 19.2979 15.3606C19.3357 15.6412 19.5968 15.9603 20.119 16.5986C20.3949 16.9358
      20.5328 17.1044 20.5908 17.2858C20.6645 17.5163 20.652 17.7658 20.5558 17.9879C20.48 18.1626 20.326 18.3167 20.0179
      18.6247L19.1243 19.5183C18.8162 19.8265 18.6621 19.9806 18.4873 20.0563C18.2652 20.1525 18.0159 20.165 17.7854 20.0914C17.6039
      20.0334 17.4352 19.8954 17.0979 19.6194L17.0979 19.6194C16.4594 19.097 16.1402 18.8358 15.8595 18.7981C15.5034 18.7502 15.149
      18.897 14.9311 19.1826C14.7593 19.4078 14.7183 19.8183 14.6362 20.6393C14.5928 21.0732 14.5711 21.2901 14.4837 21.4595C14.3728
      21.6744 14.1877 21.8419 13.9628 21.9309C13.7856 22.001 13.5676 22.001 13.1316 22.001H11.8682C11.4324 22.001 11.2145 22.001
      11.0373 21.9309C10.8123 21.842 10.6272 21.6744 10.5162 21.4594C10.4289 21.2901 10.4072 21.0732 10.3639 20.6395C10.2818 19.8186
      10.2407 19.4082 10.0689 19.183C9.85101 18.8973 9.49663 18.7506 9.14057 18.7984C8.85985 18.8362 8.54059 19.0974 7.90208
      19.6198L7.90206 19.6198C7.56473 19.8958 7.39607 20.0338 7.21458 20.0918C6.9841 20.1654 6.73474 20.153 6.51272 20.0568C6.3379
      19.9811 6.1838 19.827 5.87561 19.5188L4.98217 18.6253C4.67398 18.3171 4.51988 18.163 4.44414 17.9882C4.34794 17.7662 4.3355
      17.5168 4.40913 17.2864C4.46711 17.1049 4.60511 16.9362 4.8811 16.5989C5.40351 15.9604 5.66471 15.6411 5.70247 15.3604C5.75037
      15.0043 5.60357 14.65 5.31792 14.432C5.09273 14.2602 4.6823 14.2192 3.86143 14.1371C3.42776 14.0938 3.21093 14.0721 3.04161
      13.9847C2.82656 13.8738 2.65902 13.6887 2.57005 13.4637C2.5 13.2865 2.5 13.0686 2.5 12.6328V11.3693C2.5 10.9334 2.5 10.7154
      2.57008 10.5382C2.65905 10.3132 2.82654 10.1282 3.04152 10.0173C3.21088 9.92989 3.42777 9.9082 3.86155 9.86482H3.86155H3.86156C4.68264
      9.78271 5.09319 9.74166 5.31842 9.56979C5.60395 9.3519 5.7507 8.99761 5.70287 8.64164C5.66514 8.36085 5.40385 8.0415 4.88128
      7.40279C4.60516 7.06532 4.46711 6.89659 4.40914 6.71503C4.33557 6.48462 4.348 6.23535 4.44413 6.01341C4.51988 5.83852 4.67403
      5.68437 4.98233 5.37607L5.87565 4.48275L5.87566 4.48274C6.18385 4.17455 6.33795 4.02045 6.51277 3.9447C6.73478 3.84851 6.98414
      3.83607 7.21463 3.9097C7.39612 3.96767 7.56481 4.1057 7.9022 4.38174C8.54061 4.90408 8.85982 5.16525 9.14044 5.20304C9.49661
      5.25101 9.85113 5.10416 10.0691 4.81839C10.2408 4.59324 10.2818 4.18292 10.3639 3.36228C10.4072 2.92873 10.4289 2.71196 10.5162
      2.54268C10.6271 2.32755 10.8123 2.15996 11.0374 2.07099C11.2145 2.00098 11.4324 2.00098 11.8681 2.00098H13.1318C13.5676 2.00098
      13.7855 2.00098 13.9627 2.07102C14.1877 2.15999 14.3728 2.32753 14.4838 2.54259ZM12.5 16.001C14.7091 16.001 16.5 14.2101 16.5
      12.001C16.5 9.79184 14.7091 8.00098 12.5 8.00098C10.2909 8.00098 8.5 9.79184 8.5 12.001C8.5 14.2101 10.2909 16.001 12.5 16.001Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'SettingIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
