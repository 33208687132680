<template>
  <router-link :to="route">
    <div
      :class="getClasses"
      v-on="$listeners"
      @click="toggleActive">
      <div>
        <slot />
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'GetecmaMenuItem',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  computed: {
    getClasses() {
      return [
        'getecma-menu-item',
        this.isActive ? ' active' : '',
      ];
    },
  },
  mounted() {
    this.activeByRoute();
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    activeByRoute() {
      if (this.route
        && (this.route === this.$route.path
          || (this.route !== '/' && this.$route.path.indexOf(this.route.toLowerCase()) > -1))) {
        this.toggleActive();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './../MenuItem.scss';
</style>
