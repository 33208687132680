import { HOME_URL } from '@/modules/home/home.constants';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: HOME_URL.path,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/home/views/HomePage.vue'),
  },
  {
    ...HOME_URL.components,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/home/views/HomeComponents.vue'),
  },
];

export function goToHome($router) {
  $router.push('/');
}
