import { get, put, post, remove } from '@/helpers/http';

export function getVehicleById(id) {
  return get(`/vehicles/${id}`);
}

export function updateVehicleInformation(vehicle) {
  return put(`/vehicles/${vehicle.id}`, vehicle);
}

export function createNewVehicle(vehicle) {
  return post('/vehicles/', vehicle);
}

export function removeVehicle(vehicle) {
  return remove(`/vehicles/${vehicle.id}`);
}

export function fetchVehicles(pagination = {}) {
  return get('/vehicles', { ...pagination });
}
