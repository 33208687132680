<template>
  <el-tooltip effect="dark" :content="active ? 'Modo Claro' : 'Modo Escurdo'" placement="right">
    <el-switch v-model="active" :style="styles" @change="toggleTheme" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'GetecmaDarkmode',
  props: {
    width: {
      type: Number,
      default: 35,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    styles() {
      return `width:${this.width}px`;
    },
  },
  mounted() {
    this.setTheme(this.getTheme());
  },
  methods: {
    toggleTheme() {
      const theme = this.getTheme() === 'light' ? 'dark' : 'light';
      this.setTheme(theme);
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (hasDarkPreference) {
        return 'dark';
      }
      return 'light';
    },
    getTheme() {
      return localStorage.getItem('getecma-theme') || this.getMediaPreference();
    },
    storageTheme(theme) {
      localStorage.setItem('getecma-theme', theme);
    },
    setTheme(theme) {
      this.storageTheme(theme);
      this.active = theme === 'dark';
      document.documentElement.className = theme;
    },
  },
};
</script>

<style scoped lang="scss">
@import './Darkmode.scss';
</style>
