export const MODELS_URL = Object.freeze({
  path: '/models',
  view: {
    name: 'model.view',
    path: '/models/view/:id',
  },
  edit: {
    name: 'model.edit',
    path: '/models/edit/:id',
  },
  create: {
    name: 'model.create',
    path: '/models/create',
  },
  forbidden: {
    name: 'model.forbidden',
    path: '/models/forbidden',
  },
});
