<template>
  <el-pagination
    :background="background"
    :small="small"
    :total="total"
    :page-size="pageSize"
    :current-page.sync="localPage"
    layout="prev, pager, next"
    :hide-on-single-page="true"
    v-on="$listeners" />
</template>

<script>
export default {
  name: 'GetecmaPagination',
  inheritAttrs: false,
  props: {
    page: Number,
    pageSize: {
      type: Number,
      default: 10,
    },
    total: Number,
    small: {
      type: Boolean,
      default: false,
    },
    background: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localPage: this.page,
    };
  },
  watch: {
    localPage(page) {
      if (this.page !== page) {
        this.$emit('update:page', page);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import './Pagination.scss';
</style>
