<template>
  <getecma-modal
    ref="modal"
    title="Gerar coletas do mês"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div class="mb--lg">
        Deseja gerar as coletas para o mês de {{ monthName }}/{{ year }}?
      </div>
    </template>
    <template #footer>
      <getecma-button
        class="p--md"
        size="sm"
        :round="false"
        bg-color="success"
        @click="confirm()">
        Gerar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
import { generateCollects } from '@/modules/home/home.service';
import { toastError, toastSuccess } from '@/services/toastService';

export default {
  name: 'GetecmaGenerateCollectModal',
  data() {
    return {
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthId: null,
      monthName: null,
      year: null,
    };
  },
  mounted() {
    this.getNextMonth();
  },
  methods: {
    getNextMonth() {
      const now = new Date();
      const expiry = now.getMonth() === 11 ? new Date(now.getFullYear() + 1, 0, 1) : new Date(now.getFullYear(), now.getMonth() + 1, 1);
      this.monthId = expiry.getMonth();
      this.monthName = this.months[this.monthId];
      this.monthId++;
      this.year = expiry.getFullYear();
    },
    confirm() {
      generateCollects(this.monthId, this.year)
        .then(() => { toastSuccess('Coletas geradas!'); })
        .catch(() => {
          toastError('Erro ao gerar as coletas');
        });
      this.$emit('onGenerateCollects');
      this.$refs.modal.close();
    },
  },
};
</script>
