import { get, put, post, remove } from '@/helpers/http';

const BASE_PATH = 'user';

export function getCurrentUser() {
  return get(`${BASE_PATH}`);
}

export function checkUserByEmail(email) {
  return get(`/users/email/${email}`);
}

export function getUserById(id) {
  return get(`/users/${id}`);
}

export function updateUserInformation(user) {
  return put(`/users/${user.id}`, user);
}

export function createNewUser(user) {
  return post('/users/', user);
}

export function removeUser(user) {
  return remove(`/users/${user.id}`);
}

export function fetchUsers(pagination = {}) {
  return get('/users', { ...pagination });
}
