<template>
  <getecma-dropdown v-if="user">
    <getecma-image
      slot="content"
      fit="cover"
      :height="24"
      :width="24"
      :src="userAvatar"
      class="cursor--pointer el-avatar--circle align-self-center" />
    <template #items>
      <getecma-user-link :id="user.id">
        <el-dropdown-item disabled>
          <getecma-header size="sm" color="theme-300">
            {{ user.name }}
          </getecma-header>
          <getecma-small>
            {{ permission }}
          </getecma-small>
        </el-dropdown-item>
      </getecma-user-link>
      <router-link :to="SETTINGS_URL.default.path">
        <el-dropdown-item divided class="color--theme-100">
          <user-icon size="sm" class="me--sm" />
          Configurações
        </el-dropdown-item>
      </router-link>
      <el-dropdown-item>
        <div class="color--theme-100" @click="onLogout">
          <shutdown-icon size="sm" class="me--sm" />
          Sair
        </div>
      </el-dropdown-item>
    </template>
  </getecma-dropdown>
</template>

<script>
import { SETTINGS_URL } from '@/modules/setting/setting.constants';
import { removeCookie } from '@/helpers/cookies/cookie';
import { goToLoginPage } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES, NO_USER_IMAGE } from '@/modules/user/user.constants';
import { getImageFromAssets } from '@/helpers/images';
import { AUTH_COOKIE_NAME } from '@/modules/auth/auth.constants';

import UserIcon from '@/components/icon/User.vue';
import GetecmaUserLink from '@/modules/user/components/UserLink.vue';
import GetecmaDropdown from '@/components/dropdown/Dropdown.vue';
import ShutdownIcon from '@/components/icon/Shutdown.vue';

export default {
  name: 'GetecmaTopbarUserDropdown',
  components: {
    GetecmaUserLink,
    GetecmaDropdown,
    ShutdownIcon,
    UserIcon,
  },
  data() {
    return {
      user: getters.getUser(),
      SETTINGS_URL,
      USERS_ROLES,
    };
  },
  computed: {
    userAvatar() {
      return getImageFromAssets(NO_USER_IMAGE);
    },
    permission() {
      return `${USERS_ROLES[this.user.permission]}`;
    },
  },
  methods: {
    removeCookie,
    onLogout() {
      removeCookie(AUTH_COOKIE_NAME);
      goToLoginPage();
    },
  },
};
</script>
<style scoped lang="scss">
@import './TopbarUserDropdown.scss';
</style>
