import { SETTINGS_URL } from '@/modules/setting/setting.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: '/settings',
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...SETTINGS_URL.default,
        component: () => import('@/modules/setting/views/SettingsPage.vue'),
      },
    ],
  },
];
