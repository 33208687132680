<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1515 20.4064C20.7043 20.2912 21.0336 19.7127 20.7589 19.2193C20.1533 18.1316 19.1993 17.1759 17.9788 16.4475C16.407 15.5094
      14.4812 15.001 12.5 15.001C10.5188 15.001 8.59292 15.5094 7.02112 16.4475C5.80069 17.1759 4.84666 18.1316 4.24108 19.2193C3.96638
      19.7127 4.29562 20.2911 4.84843 20.4064C9.89524 21.4581 15.1047 21.4581 20.1515 20.4064Z" />
    <circle
      cx="12.5"
      cy="8.00098"
      r="5" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'UserIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
