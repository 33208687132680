<template>
  <div class="form-field">
    <label v-if="label" class="form-label">{{ label }}</label>
    <voerro-tags-input
      v-model="localValue"
      placeholder=""
      :typeahead="true"
      typeahead-style="dropdown"
      :existing-tags="suggestions"
      :add-tags-on-space="true"
      :typeahead-max-results="5"
      :typeahead-hide-discard="true"
      wrapper-class="tags-input-wrapper-default el-input"
      @tag-added="onTagAdded"
      @tag-removed="onTagRemoved">
      <template #selected-tag="{ tag, index, removeTag }">
        <getecma-tag
          :label="tag.value"
          :closable="true"
          class="ms--md my--sm"
          @click="removeTag(index)"
          @close="removeTag(index)" />
      </template>
    </voerro-tags-input>
  </div>
</template>

<script>
import '@voerro/vue-tagsinput/dist/style.css';
import VoerroTagsInput from '@voerro/vue-tagsinput';

export default {
  name: 'GetecmaTagInput',
  components: {
    VoerroTagsInput,
  },
  inheritAttrs: false,
  props: {
    /**
     * Array of id/name values
     */
    value: {
      type: Array,
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    /**
     * Array of id/name values
     */
    suggestions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.addTagFormat(this.value),
    };
  },
  methods: {
    addTagFormat(list) {
      return (list || []).map(item => ({ ...item, key: item.id, value: item.name }));
    },
    onTagAdded() {
      this.$emit('change', this.localValue);
    },
    onTagRemoved() {
      this.$emit('change', this.localValue);
    },
  },
};
</script>
