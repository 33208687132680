export const MY_SPACE_URL = Object.freeze({
  path: '/my-space',
  users: {
    name: 'my-space.users',
    path: '/users',
  },
  models: {
    name: 'my-space.models',
    path: '/models',
  },
  vehicles: {
    name: 'my-space.vehicles',
    path: '/vehicles',
  },
  cities: {
    name: 'my-space.cities',
    path: '/cities',
  },
  neighborhoods: {
    name: 'my-space.neighborhoods',
    path: '/neighborhoods',
  },
  regions: {
    name: 'my-space.regions',
    path: '/regions',
  },
  employees: {
    name: 'my-space.employees',
    path: '/employees',
  },
  customers: {
    name: 'my-space.customers',
    path: '/customers',
  },
  recurrences: {
    name: 'my-space.recurrences',
    path: '/recurrences',
  },
  capacities: {
    name: 'my-space.capacities',
    path: '/capacities',
  },
  groups: {
    name: 'my-space.groups',
    path: '/groups',
  },
});
