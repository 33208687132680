import authRoutes from '@/modules/auth/auth.routes';
import homeRoutes from '@/modules/home/home.routes';
import errorRoutes from '@/modules/error/error.routes';
import mySpaceRoutes from '@/modules/my-space/my-space.routes';
import settingRoutes from '@/modules/setting/setting.routes';
import userRoutes from '@/modules/user/user.routes';
import modelRoutes from '@/modules/model/model.routes';
import vehicleRoutes from '@/modules/vehicle/vehicle.routes';
import cityRoutes from '@/modules/city/city.routes';
import neighborhoodRoutes from '@/modules/neighborhood/neighborhood.routes';
import regionRoutes from '@/modules/region/region.routes';
import employeeRoutes from '@/modules/employee/employee.routes';
import customerRoutes from '@/modules/customer/customer.routes';
import recurrenceRoutes from '@/modules/recurrence/recurrence.routes';
import capacityRoutes from '@/modules/capacity/capacity.routes';
import groupRoutes from '@/modules/group/group.routes';

const routes = [
  {
    path: '*',
    redirect: '/',
  },
];

export default [
  ...routes,
  ...authRoutes,
  ...homeRoutes,
  ...errorRoutes,
  ...mySpaceRoutes,
  ...settingRoutes,
  ...userRoutes,
  ...modelRoutes,
  ...vehicleRoutes,
  ...cityRoutes,
  ...neighborhoodRoutes,
  ...regionRoutes,
  ...employeeRoutes,
  ...customerRoutes,
  ...recurrenceRoutes,
  ...capacityRoutes,
  ...groupRoutes,
];
