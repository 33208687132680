<template>
  <div>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaApp',
  provide() {
    const userEditVm = {};
    Object.defineProperty(userEditVm, 'user', {
      get: () => getters.getUser(),
    });
    return { userEditVm };
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
@import '@/scss/app';
</style>
