<template>
  <getecma-modal
    ref="modal"
    title="Atenção"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div v-if="content">
        {{ content }}
      </div>
      <div v-else>
        Você tem certeza que deseja excluir este item?
      </div>
      <slot name="subtitle" />
    </template>
    <template #footer>
      <slot name="footer" />
      <getecma-button
        bg-color="danger"
        size="sm"
        class="p--md"
        :round="false"
        @click="confirm()">
        Excluir
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
export default {
  name: 'GetecmaModalDelete',
  props: {
    closeAfterConfirm: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      if (this.closeAfterConfirm) {
        this.$refs.modal.close();
      }
    },
  },
};
</script>
