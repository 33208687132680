<template>
  <el-aside :style="styles" :class="classes">
    <slot />
  </el-aside>
</template>

<script>
import { isMinLayout } from '@/helpers/layout/layout';

export default {
  name: 'GetecmaNavbar',
  props: {
    bgColor: String,
  },
  computed: {
    styles() {
      return `width: ${isMinLayout() ? '100%' : 'fit-content'};`;
    },
    classes() {
      return `${isMinLayout() ? 'pe--xl' : 'pb--xl'} bg-color--${this.bgColor}`;
    },
  },
};
</script>
