<template>
  <getecma-modal
    ref="modal"
    :title="modalTitle"
    v-bind="$attrs"
    v-on="$listeners">
    <template #content>
      <div>
          <getecma-header size="sm">Listar apenas grupos?</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <el-switch v-model="listGroups" active-text="Sim" inactive-text="Não" />
        </div>
      </div>
      <div v-if="listGroups" class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <div>
              <getecma-header size="sm"> Grupo de clientes*</getecma-header>
          </div>
          <getecma-select
            name="grupos de clientes"
            placeholder="Selecione um grupo"
            :options="groups"
            @on-change="onGroupChange">
          </getecma-select>
        </div>
      </div>
      <div v-else class="row mt--xl">
        <div>
            <getecma-header size="sm">Listar apenas clientes por acionamento?</getecma-header>
        </div>
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <el-switch v-model="listCustomerPerActivation" active-text="Sim" inactive-text="Não" />
          </div>
        </div>
        <div v-if="listCustomerPerActivation" class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Cliente por acionamento*</getecma-header>
            </div>
            <getecma-select
              name="cliente por acionamento"
              placeholder="Selecione um cliente por acionamento"
              :options="customersPerActivation"
              @on-change="onCustomerChange">
            </getecma-select>
          </div>
        </div>
        <div v-else class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Cliente*</getecma-header>
            </div>
            <getecma-select
              name="clientes"
              placeholder="Selecione um cliente"
              :options="customers"
              @on-change="onCustomerChange">
            </getecma-select>
          </div>
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <div>
              <getecma-header size="sm"> Motorista*</getecma-header>
          </div>
          <getecma-select
            name="motista"
            placeholder="Selecione um motorista"
            :options="employees"
            @on-change="onDriverChange">
          </getecma-select>
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <div>
              <getecma-header size="sm"> Ajudante*</getecma-header>
          </div>
          <getecma-select
            name="motista"
            placeholder="Selecione um ajudante"
            :options="employees"
            @on-change="onHelperChange">
          </getecma-select>
        </div>
      </div>
      <div v-if="calendarAllDay">
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Horário Inicial*</getecma-header>
            </div>
            <el-time-select
              v-model="calendarStartAllDay"
              placeholder="Horário Inicial"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '15:00'
              }">
            </el-time-select>
          </div>
        </div>
        <div class="row mt--xl">
          <div class="form-field col-18 col-lg-9">
            <div>
                <getecma-header size="sm"> Horário Final*</getecma-header>
            </div>
            <el-time-select
              v-model="calendarEndAllDay"
              placeholder="Horário Final"
              :picker-options="{
                start: '06:00',
                step: '00:30',
                end: '15:30',
                minTime: calendarStartAllDay
              }">
            </el-time-select>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <getecma-button
        class="p--md"
        size="sm"
        :round="false"
        bg-color="success"
        @click="confirm()">
        Criar
      </getecma-button>
    </template>
  </getecma-modal>
</template>

<script>
import { fetchAllCustomersPerActivation, fetchCustomersCollects } from '@/modules/customer/customer.service';
import { fetchEmployees } from '@/modules/employee/employee.service';
import { getVehicleById } from '@/modules/vehicle/vehicle.service';
import { fetchGroups } from '@/modules/group/group.service';
import { toastError } from '@/services/toastService';
import { EMPLOYEES_ROLES } from '@/modules/employee/employee.constants';

export default {
  name: 'GetecmaAddCollectModal',
  inject: ['collectVm'],
  props: {
    calendarStart: {
      type: String,
      default: null,
    },
    calendarEnd: {
      type: String,
      default: null,
    },
    vehicleId: {
      type: String,
    },
    calendarAllDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customersPerActivation: [],
      customers: [],
      groups: [],
      employees: [],
      vehicle: null,
      modalTitle: null,
      collect: this.collectVm.collect,
      listCustomerPerActivation: true,
      listGroups: true,
      calendarStartAllDay: null,
      calendarEndAllDay: null,
    };
  },
  mounted() {
    this.fetchCustomersCollects();
    this.fetchCustomersPerActivation();
    this.fetchEmployees();
    this.getVehicleById();
    this.fetchGroups();
  },
  methods: {
    getVehicleById() {
      getVehicleById(this.vehicleId)
        .then(data => {
          this.modalTitle = `Adicionar coleta - Veículo ${data.license_plate}`;
          this.collect.color = data.color_hex;
          this.duration = `00:${data.duration}`;
        });
    },
    async fetchGroups() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchGroups(params);
        this.groups = response.rows.map(group => ({
          key: group.id,
          value: group.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os grupos:', error);
      }
    },
    async fetchEmployees() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchEmployees(params);
        this.employees = response.rows.map(employee => ({
          key: employee.id,
          value: employee.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os funcionários:', error);
      }
    },
    async fetchCustomersCollects() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCustomersCollects(params);
        this.customers = response.rows.map(customer => ({
          key: customer.id,
          value: customer.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os clientes:', error);
      }
    },
    async fetchCustomersPerActivation() {
      try {
        const response = await fetchAllCustomersPerActivation();
        this.customersPerActivation = response.rows.map(customer => ({
          key: customer.id,
          value: customer.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os clientes:', error);
      }
    },
    onGroupChange(group) {
      this.collect.group_id = group.key;
      this.collect.customer_id = null;
      this.collect.title = group.value;
    },
    onCustomerChange(customer) {
      this.collect.group_id = null;
      this.collect.customer_id = customer.key;
      this.collect.title = customer.value;
    },
    onDriverChange(employee) {
      this.collect.employees.push({ employee_id: employee.key, function: EMPLOYEES_ROLES.DRIVER });
    },
    onHelperChange(employee) {
      this.collect.employees.push({ employee_id: employee.key, function: EMPLOYEES_ROLES.HELPER });
    },
    async confirm() {
      if (this.collect.customer_id == null) {
        toastError('Selecione um cliente');
      } else if (this.collect.employees.length < 2) {
        toastError('Informe o motorista e o ajudante da coleta');
      } else if (this.calendarAllDay && (this.calendarStartAllDay == null || this.calendarEndAllDay == null)) {
        toastError('Informe o horário corretamente, inicial e final');
      } else {
        if (this.calendarAllDay) {
          this.collect.start = `${this.calendarStart}T${this.calendarStartAllDay}:00-03:00`;
          this.collect.end = `${this.calendarStart}T${this.calendarEndAllDay}:00-03:00`;
        } else {
          this.collect.start = this.calendarStart;
          this.collect.end = this.calendarEnd;
        }
        this.collect.vehicle_id = this.vehicleId;
        this.collect.allDay = false;
        this.$emit('onAddCollect');
        this.$refs.modal.close();
      }
    },
  },
};
</script>

<style lang='css'>
  .el-input__icon {
    display: none;
  }
  .fc-daygrid-event {
    overflow: hidden;
  }
  .fc-timegrid-event {
    overflow: hidden;
  }
</style>
