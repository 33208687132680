<template>
  <div class="form-field">
    <validation-provider v-slot="{ reset, failed }" :rules="rules">
      <label v-if="label" class="form-label fs--lg fw--strong">{{ label }}</label>
      <el-select
        ref="select"
        v-model="localValue"
        :multiple="multiple"
        :name="name"
        filterable
        :disabled="disabled"
        style="width: 100%"
        :placeholder="placeholder"
        @change="reset"
        v-on="$listeners">
        <el-option
          v-for="item in options"
          :key="item.key"
          :label="item.value"
          :value="{key: item.key, value: item.value}">
        </el-option>
      </el-select>
      <br>
      <small v-if="failed" class="text-danger fs--sm">
        O campo {{ name }} é obrigatório
      </small>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: 'GetecmaSelect',
  inheritAttrs: false,
  props: {
    label: String,
    placeholder: String,
    options: Array,
    disabled: Boolean,
    multiple: Boolean,
    rules: String,
    value: {
      type: [Number, String, Object, Array],
      default: () => null,
    },
    name: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue(value) {
      if (this.value !== value) {
        this.$emit('input', value);
        this.$emit('on-change', value);
      }
    },
  },
};
</script>
