import { get, put, post, remove } from '@/helpers/http';

export function getCollectById(id) {
  return get(`/collects/${id}`);
}

export function updateCollectInformation(collect) {
  return put(`/collects/${collect.id}`, collect);
}

export function createNewCollect(collect) {
  return post('/collects/', collect);
}

export function removeCollect(collectId) {
  return remove(`/collects/${collectId}`);
}

export function fetchCollects(vehicleId) {
  return get('/collects', { vehicle_id: vehicleId });
}

export function fetchEmployeesByCollect(idCollect) {
  return post('/employess-collect', { collect_id: idCollect });
}

export function generateCollects(month, year) {
  return post('/generate-collects/', { month, year });
}

export function fetchHolidays() {
  return get('/holidays');
}
