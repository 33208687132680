import { RECURRENCES_URL }
from '@/modules/recurrence/recurrence.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: RECURRENCES_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...RECURRENCES_URL.view,
        component: () => import('@/modules/recurrence/views/RecurrenceViewPage.vue'),
      },
      {
        ...RECURRENCES_URL.edit,
        component: () => import('@/modules/recurrence/views/RecurrenceEditPage.vue'),
      },
      {
        ...RECURRENCES_URL.create,
        component: () => import('@/modules/recurrence/views/RecurrenceEditPage.vue'),
      },
      {
        ...RECURRENCES_URL.forbidden,
        component: () => import('@/modules/recurrence/views/RecurrenceForbiddenPage.vue'),
      },
    ],
  },
];

export function goToRecurrenceForbiddenPage($router, creating) {
  $router.push({
    name: RECURRENCES_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenRecurrence($router, recurrence) {
  $router.push({
    name: RECURRENCES_URL.view.name,
    params: { id: recurrence.id },
  });
}

export function goToCreateRecurrence($router) {
  $router.push({
    name: RECURRENCES_URL.create.name,
  });
}
