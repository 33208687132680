import { REGIONS_URL } from '@/modules/region/region.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: REGIONS_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...REGIONS_URL.view,
        component: () => import('@/modules/region/views/RegionViewPage.vue'),
      },
      {
        ...REGIONS_URL.edit,
        component: () => import('@/modules/region/views/RegionEditPage.vue'),
      },
      {
        ...REGIONS_URL.create,
        component: () => import('@/modules/region/views/RegionEditPage.vue'),
      },
      {
        ...REGIONS_URL.forbidden,
        component: () => import('@/modules/region/views/RegionForbiddenPage.vue'),
      },
    ],
  },
];

export function goToRegionForbiddenPage($router, creating) {
  $router.push({
    name: REGIONS_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenRegion($router, region) {
  $router.push({
    name: REGIONS_URL.view.name,
    params: { id: region.id },
  });
}

export function goToCreateRegion($router) {
  $router.push({
    name: REGIONS_URL.create.name,
  });
}
