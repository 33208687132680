<template>
<svg fill="#null" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" xml:space="preserve">
<g>
    <path d="M473.025,347.268l-39.01-66.418H303.509L264.5,347.268v112.107h-34V347.268l-39.009-66.418H60.984l-39.009,66.418v112.107
      H0v30h495v-30h-21.975V347.268z M416.844,310.85l14.976,25.497H305.706l14.975-25.497H416.844z M294.5,366.347h148.525v93.028
      h-59.263v-58.028h-30v58.028H294.5V366.347z M174.319,310.85l14.975,25.497H63.181l14.975-25.497H174.319z M51.975,366.347H200.5
      v93.028h-59.263v-58.028h-30v58.028H51.975V366.347z" />
    <path d="M473.025,184.15V72.043l-39.01-66.418H303.509L264.5,72.043V184.15h-34V72.043L191.491,5.625H60.984L21.975,72.043V184.15
      H0v30h495v-30H473.025z M416.844,35.625l14.976,25.498H305.706l14.975-25.498H416.844z M294.5,91.123h148.525v93.027h-59.263
      v-58.027h-30v58.027H294.5V91.123z M174.319,35.625l14.975,25.498H63.181l14.975-25.498H174.319z M51.975,91.123H200.5v93.027
      h-59.263v-58.027h-30v58.027H51.975V91.123z" />
</g>
</svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'NeighborhoodIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
