import { GROUPS_URL } from '@/modules/group/group.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: GROUPS_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...GROUPS_URL.view,
        component: () => import('@/modules/group/views/GroupViewPage.vue'),
      },
      {
        ...GROUPS_URL.edit,
        component: () => import('@/modules/group/views/GroupEditPage.vue'),
      },
      {
        ...GROUPS_URL.create,
        component: () => import('@/modules/group/views/GroupEditPage.vue'),
      },
      {
        ...GROUPS_URL.forbidden,
        component: () => import('@/modules/group/views/GroupForbiddenPage.vue'),
      },
    ],
  },
];

export function goToGroupForbiddenPage($router, creating) {
  $router.push({
    name: GROUPS_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenGroup($router, group) {
  $router.push({
    name: GROUPS_URL.view.name,
    params: { id: group.id },
  });
}

export function goToCreateGroup($router) {
  $router.push({
    name: GROUPS_URL.create.name,
  });
}
