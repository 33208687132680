<template>
  <div class="d--flex direction--column align-items--center mt--xl">
    <getecma-icon :icon="icon" :size="iconSize" class="opacity--50 mx--lg" />
    <getecma-p v-if="description" :color="color" size="md" class="opacity--50 m--lg">{{ description }}</getecma-p>
    <div v-if="$slots.footer" class="m--lg">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetecmaEmpty',
  props: {
    icon: {
      type: String,
      default: 'box',
    },
    iconSize: {
      type: String,
      default: 'xl',
    },
    color: {
      type: String,
      default: 'theme-900-100',
    },
    description: {
      type: String,
      default: 'No data to be displayed!',
    },
  },
};
</script>
