export const USERS_URL = Object.freeze({
  path: '/users',
  view: {
    name: 'user.view',
    path: '/users/view/:id',
  },
  edit: {
    name: 'user.edit',
    path: '/users/edit/:id',
  },
  create: {
    name: 'user.create',
    path: '/users/create',
  },
  forbidden: {
    name: 'user.forbidden',
    path: '/users/forbidden',
  },
});

export const NO_USER_IMAGE = 'no-profile.png';

export const USERS_ROLES = Object.freeze({
  VIEWER: 0,
  ADMIN: 1,
  0: 'Visualizador',
  1: 'Administrador',
});
