import { CAPACITIES_URL }
from '@/modules/capacity/capacity.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: CAPACITIES_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...CAPACITIES_URL.view,
        component: () => import('@/modules/capacity/views/CapacityViewPage.vue'),
      },
      {
        ...CAPACITIES_URL.edit,
        component: () => import('@/modules/capacity/views/CapacityEditPage.vue'),
      },
      {
        ...CAPACITIES_URL.create,
        component: () => import('@/modules/capacity/views/CapacityEditPage.vue'),
      },
      {
        ...CAPACITIES_URL.forbidden,
        component: () => import('@/modules/capacity/views/CapacityForbiddenPage.vue'),
      },
    ],
  },
];

export function goToCapacityForbiddenPage($router, creating) {
  $router.push({
    name: CAPACITIES_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenCapacity($router, capacity) {
  $router.push({
    name: CAPACITIES_URL.view.name,
    params: { id: capacity.id },
  });
}

export function goToCreateCapacity($router) {
  $router.push({
    name: CAPACITIES_URL.create.name,
  });
}
