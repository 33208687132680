import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    ...MY_SPACE_URL.users,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceUsers.vue'),
  },
  {
    ...MY_SPACE_URL.models,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceModels.vue'),
  },
  {
    ...MY_SPACE_URL.vehicles,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceVehicles.vue'),
  },
  {
    ...MY_SPACE_URL.cities,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceCities.vue'),
  },
  {
    ...MY_SPACE_URL.neighborhoods,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceNeighborhoods.vue'),
  },
  {
    ...MY_SPACE_URL.regions,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceRegions.vue'),
  },
  {
    ...MY_SPACE_URL.employees,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceEmployees.vue'),
  },
  {
    ...MY_SPACE_URL.customers,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceCustomers.vue'),
  },
  {
    ...MY_SPACE_URL.recurrences,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceRecurrences.vue'),
  },
  {
    ...MY_SPACE_URL.capacities,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceCapacities.vue'),
  },
  {
    ...MY_SPACE_URL.groups,
    redirect: '',
    beforeEnter: resolveCurrentUser,
    component: () => import('@/modules/my-space/views/MySpaceGroups.vue'),
  },
];
