<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 21.001C17.4706 21.001 21.5 16.9715 21.5 12.001C21.5 7.03041 17.4706 3.00098 12.5 3.00098C7.52944
      3.00098 3.5 7.03041 3.5 12.001C3.5 16.9715 7.52944 21.001 12.5 21.001ZM12.5 5.00098C13.0523 5.00098 13.5
      5.44869 13.5 6.00098V9.00098C13.5 9.55326 13.0523 10.001 12.5 10.001C11.9477 10.001 11.5 9.55326 11.5 9.00098V6.00098C11.5
      5.44869 11.9477 5.00098 12.5 5.00098ZM16.0355 7.05123C16.4261 6.6607 17.0592 6.6607 17.4497 7.05123C18.4287 8.0302
      19.0954 9.27748 19.3655 10.6353C19.6356 11.9932 19.497 13.4007 18.9672 14.6798C18.4373 15.9588 17.5401 17.0521 16.389
      17.8213C15.2378 18.5904 13.8845 19.001 12.5 19.001C11.1155 19.001 9.76215 18.5904 8.61101 17.8213C7.45986 17.0521 6.56265
      15.9588 6.03284 14.6798C5.50303 13.4007 5.3644 11.9932 5.6345 10.6353C5.9046 9.27748 6.57128 8.0302 7.55025 7.05123C7.94077
      6.66071 8.57394 6.66071 8.96446 7.05123C9.35499 7.44175 9.35499 8.07492 8.96446 8.46544C8.2652 9.16471 7.789 10.0556 7.59607
      11.0255C7.40314 11.9954 7.50216 13.0008 7.8806 13.9144C8.25904 14.828 8.8999 15.6089 9.72215 16.1583C10.5444 16.7077 11.5111
      17.001 12.5 17.001C13.4889 17.001 14.4556 16.7077 15.2778 16.1583C16.1001 15.6089 16.741 14.828 17.1194 13.9144C17.4978 13.0008
      17.5968 11.9954 17.4039 11.0255C17.211 10.0556 16.7348 9.1647 16.0355 8.46544C15.645 8.07492 15.645 7.44175 16.0355 7.05123Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'ShutdownIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
