const TIME_IN_MILLISECONDS = Object.freeze({
  HOUR: 3600000,
  MINUTE: 60000,
  SECOND: 1000,
});

function getTime(milliseconds, format) {
  if (format === 'hour') return milliseconds / TIME_IN_MILLISECONDS.HOUR;
  if (format === 'minute') return milliseconds / TIME_IN_MILLISECONDS.MINUTE;
  if (format === 'second') return milliseconds / TIME_IN_MILLISECONDS.SECOND;
  return 0;
}

export function getHours(milliseconds) {
  return getTime(milliseconds, 'hour');
}

export function getMinutes(milliseconds) {
  return getTime(milliseconds, 'minute');
}

export function getSeconds(milliseconds) {
  return getTime(milliseconds, 'second');
}

export function getMonthRange() {
  const beginDate = new Date();
  beginDate.setMonth(beginDate.getMonth() - 1);

  return {
    start: beginDate.toISOString().substring(0, 10),
    end: (new Date()).toISOString().substring(0, 10),
  };
}
