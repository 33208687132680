import { NEIGHBORHOODS_URL } from '@/modules/neighborhood/neighborhood.constants';
import { createEmptyComponent } from '@/router/route.service';
import { resolveCurrentUser } from '@/modules/user/user.service';

export default [
  {
    path: NEIGHBORHOODS_URL.path,
    redirect: '/',
    beforeEnter: resolveCurrentUser,
    component: { render: createEmptyComponent },
    children: [
      {
        ...NEIGHBORHOODS_URL.view,
        component: () => import('@/modules/neighborhood/views/NeighborhoodViewPage.vue'),
      },
      {
        ...NEIGHBORHOODS_URL.edit,
        component: () => import('@/modules/neighborhood/views/NeighborhoodEditPage.vue'),
      },
      {
        ...NEIGHBORHOODS_URL.create,
        component: () => import('@/modules/neighborhood/views/NeighborhoodEditPage.vue'),
      },
      {
        ...NEIGHBORHOODS_URL.forbidden,
        component: () => import('@/modules/neighborhood/views/NeighborhoodForbiddenPage.vue'),
      },
    ],
  },
];

export function goToNeighborhoodForbiddenPage($router, creating) {
  $router.push({
    name: NEIGHBORHOODS_URL.forbidden.name,
    params: { creating },
  });
}

export function goToOpenNeighborhood($router, neighborhood) {
  $router.push({
    name: NEIGHBORHOODS_URL.view.name,
    params: { id: neighborhood.id },
  });
}

export function goToCreateNeighborhood($router) {
  $router.push({
    name: NEIGHBORHOODS_URL.create.name,
  });
}
